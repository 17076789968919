import { Box, Tooltip, Infobox, Tag } from '@flixbus/honeycomb-react';
import type { Trip, Passenger, Ride } from '../types';
import {
    Icon,
    IconArrowBigRight,
    IconQuestion,
    IconBike,
    IconSeat,
} from '@flixbus/honeycomb-icons-react';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';
import {
    FULL_DATE_TIME_FORMAT,
    TIME_ONLY_FORMAT,
    formatDateTime,
    getDiffReadableString,
} from '../../utils';
import RideUid from '../RideUid';
import type { Route, BreakingPoint } from './types';
import OrdersCollection from '../../dataLayer/classes/OrdersCollection';
import { Fragment } from 'react';

interface BatchOrderSummaryProps {
    originTrip: Route;
    newTrip?: Route;
    originalTrip?: Pick<Trip, 'arrivalTime' | 'departureTime'>;
    breakingPoint?: BreakingPoint;
    orders: OrdersCollection;
    type?: 'new' | 'origin';
}

function BatchOrderSummary({
    originTrip,
    newTrip,
    orders,
    breakingPoint,
    type = 'origin',
}: BatchOrderSummaryProps) {
    const translate: Translate = useTranslate();
    const title =
        type === 'origin' ? translate('original-trip') : translate('new-trip');
    const departureText =
        originTrip.departureTime === null
            ? '--'
            : formatDateTime(
                  originTrip.departureTime,
                  FULL_DATE_TIME_FORMAT,
                  true
              );
    const arrivalText =
        originTrip.arrivalTime === null
            ? '--'
            : formatDateTime(originTrip.arrivalTime, TIME_ONLY_FORMAT, true);
    const breakingPointTime = breakingPoint?.departureTime
        ? formatDateTime(
              breakingPoint.departureTime,
              FULL_DATE_TIME_FORMAT,
              true
          )
        : undefined;
    const diff = getDiffReadableString(
        originTrip.arrivalTime,
        breakingPoint?.departureTime || originTrip.departureTime
    );

    let passengers: Passenger[] = [];
    orders.forEach((order) => {
        if (order?.orderDetails?.passengers?.length) {
            passengers = [...passengers, ...order.orderDetails.passengers];
        }
    });

    return newTrip ? (
        <Box extraClasses={`order-info order-info--summary`}>
            <div className="order-info__icon"></div>
            <div className="order-info__main order-info__wrapper">
                <div>
                    <strong>{title}: </strong>
                    {breakingPoint ? (
                        <>
                            <span className="order-info--fade">
                                {originTrip.startStationName}
                            </span>{' '}
                            <Icon InlineIcon={IconArrowBigRight} size={2} />{' '}
                            {breakingPoint.stationName}
                            <Tooltip
                                id={`${breakingPoint.stationName}-tooltip`}
                                content="Manual selected"
                                openOnHover
                            >
                                <Icon
                                    InlineIcon={IconQuestion}
                                    appearance="secondary"
                                />
                            </Tooltip>
                        </>
                    ) : (
                        originTrip.startStationName
                    )}{' '}
                    <Icon InlineIcon={IconArrowBigRight} size={2} />{' '}
                    {originTrip.destinationStationName}
                </div>
                <div>
                    {breakingPointTime ? (
                        <>
                            <span className="order-info--fade">
                                {departureText}
                            </span>{' '}
                            - {breakingPointTime}
                        </>
                    ) : (
                        departureText
                    )}{' '}
                    - {arrivalText}
                    {originTrip.arrivalNextDays && (
                        <sup>{originTrip.arrivalNextDays}</sup>
                    )}
                    {diff ? ` (${diff})` : null}{' '}
                </div>
                <div className="order-info__uid">
                    {/* @ts-ignore */}
                    {originTrip.icInfo?.length ? (
                        /* @ts-ignore */
                        originTrip.icInfo.map((ride: Ride) => (
                            <Fragment key={ride.rideUuid}>
                                <RideUid
                                    rideUuid={ride.rideUuid}
                                    rideUid={ride.rideUid}
                                    color={ride.lineColor}
                                />{' '}
                            </Fragment>
                        ))
                    ) : (
                        <RideUid
                            rideUuid={originTrip.rideId || ''}
                            rideUid={originTrip.rideUid}
                            color={originTrip.lineColor}
                        />
                    )}{' '}
                    →{' '}
                    {newTrip.rides?.length &&
                        newTrip.rides.map((ride: Ride) => (
                            <Fragment key={ride.rideUuid}>
                                <RideUid
                                    rideUuid={ride.rideUuid}
                                    rideUid={ride.rideUid}
                                    color={ride.lineColor}
                                />{' '}
                            </Fragment>
                        ))}
                </div>
                {orders?.length ? (
                    <div>
                        <strong>Orders: </strong>
                        {orders.length}
                    </div>
                ) : null}
                {passengers?.length ? (
                    <div>
                        <strong>Passengers: </strong>
                        {passengers.length}
                    </div>
                ) : null}
            </div>
            <div className="order-info__tags">
                {newTrip.capacity && (
                    <>
                        <Tag display="outlined">
                            <Icon InlineIcon={IconSeat} appearance="primary" />
                            {newTrip.capacity.seats}
                        </Tag>

                        <Tag display="outlined">
                            <Icon InlineIcon={IconBike} appearance="primary" />
                            {newTrip.capacity.slots}
                        </Tag>
                    </>
                )}
            </div>
        </Box>
    ) : (
        <Infobox appearance="danger" small>
            {translate('missing-suggestion-data')}
        </Infobox>
    );
}

export default BatchOrderSummary;
