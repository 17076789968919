import {
    Icon,
    IconBikeSolid,
    IconBikeNoSolid,
    IconSeatSolid,
} from '@flixbus/honeycomb-icons-react';

import './CapacityBlock.scss';
import { IconAlign } from '../HelpersModifiers';

type CapacityBlockProps = {
    seats: number;
    slots: number;
};

export default function CapacityBlock({ seats, slots }: CapacityBlockProps) {
    return (
        <div className="rbk-capacity-block">
            <span className="rbk-capacity-icon">
                <Icon appearance="primary" InlineIcon={IconSeatSolid} />
                &nbsp;
                <IconAlign>{seats}</IconAlign>
            </span>
            <span className="rbk-capacity-icon">
                {slots <= 0 ? (
                    <Icon
                        extraClasses="rbk-capacity-icon--danger"
                        InlineIcon={IconBikeNoSolid}
                    />
                ) : (
                    <Icon appearance="primary" InlineIcon={IconBikeSolid} />
                )}
                &nbsp;
                <IconAlign>{slots}</IconAlign>
            </span>
        </div>
    );
}
