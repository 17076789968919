import React, { useReducer } from 'react';
import { AppStateProviderValue, AppStateProviderProps } from './types';
import reducer from './reducer';
import initState from './initState';

const AppStateContext = React.createContext<AppStateProviderValue>({
    state: {},
    dispatch: () => {},
});

export default function AppStateProvider({ children }: AppStateProviderProps) {
    const [state, dispatch] = useReducer(reducer, initState);
    return (
        <AppStateContext.Provider value={{ state, dispatch }}>
            {children}
        </AppStateContext.Provider>
    );
}

export { AppStateContext };
