import {
    useQuery,
    QueryObserverOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import { api } from '../api';
import { Stop, AutocompleteStopsList } from '../components/types';

function genAutocompleteOptions(data: Stop[]): AutocompleteStopsList[] {
    return data
        .filter((s) => {
            if (!s.name || !s.countryCode) {
                return false;
            }
            return (
                s.name.search(/(do not use|don't|activate)/i) === -1 &&
                s.countryCode.search(/ru/i) === -1
            );
        })
        .map((item) => ({
            title: `${item.code} - ${item.name}`,
            id: item.id,
            key: `${item.id} - ${item.name}`,
            name: item.name,
            code: item.code,
        }));
}

async function fetch(): Promise<AutocompleteStopsList[]> {
    const url: string = '/stations/all';
    try {
        const response = await api.get(url);
        return genAutocompleteOptions(response.data.body);
    } catch (e: any) {
        if (e.response.data?.error && e.response?.data.errorMsg) {
            throw new Error(
                `${e.response.data.error}: ${e.response.data.errorMsg}`
            );
        }
        throw new Error(`${e.code || e.name}: ${e.message}`);
    }
}

export default function useStopsList(
    options: QueryObserverOptions<AutocompleteStopsList[], string> = {}
): UseQueryResult<AutocompleteStopsList[], string> {
    return useQuery({
        queryKey: ['stopsList'],
        queryFn: fetch,
        staleTime: 900000,
        cacheTime: 900000, // 15 minutes
        ...options,
    });
}
