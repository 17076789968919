import { Configuration } from 'rollbar';
import { getUser } from '../auth/index';

const rollbarConfig: Configuration = {
    enabled:
        process.env.REACT_APP_ENV === undefined &&
        process.env.NODE_ENV === 'production',
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN || undefined,
    captureUncaught: true,
    captureUnhandledRejections: true,
    addErrorContext: true,
    payload: {
        environment: process.env.NODE_ENV === 'production' ? 'prod' : 'staging',
        person: {
            id: getUser()?.id || 'n/a',
            name: getUser()?.userName,
        },
    },
    transform: (payload) => {
        try {
            // @ts-ignore;
            payload.body.trace.exception.class = `[Rebookr2.0-SPA] ${payload.body.trace.exception.class}`;
        } catch {
            console.log('payload.body.trace.exception.message, not changed');
        }
    },
};

export default rollbarConfig;
