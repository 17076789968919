import validateOrder from '../validateOrders';
import Order, { OrderAttrs } from './Order';
import Trip, { Suggestion } from './Trip';

export type GroupByTrip = Map<string, Map<number, Order>>;
export type SuggestionByTrip = Map<string, Map<string, Suggestion>>;
export type TripInfoBase = Pick<
    Trip,
    | 'arrivalTime'
    | 'departureTime'
    | 'startStationName'
    | 'destinationStationName'
    | 'startStationId'
    | 'destinationStationId'
>;

export default class OrdersCollection extends Array<Order> {
    byTrip: GroupByTrip;
    invalidOrders: Order[];
    suggestionsByTrip: SuggestionByTrip;
    pageSize: number = 20;
    currentPage: number = 1;
    totalPages!: number;

    #groupByTrip(): GroupByTrip {
        let gMap = new Map();
        this.forEach((item: Order) => {
            if (validateOrder(item) === false) {
                return;
            }
            const trip = item.trip;
            const key = `${trip?.startStationName}-${trip?.destinationStationName}`;
            if (gMap.has(key)) {
                let oMap = gMap.get(key);
                oMap.set(item.orderId as number, item);
            } else {
                let oMap = new Map();
                oMap.set(item.orderId as number, item);
                gMap.set(key, oMap);
            }
        });

        return gMap;
    }
    #getInvalidOrders(): Order[] {
        let invalidOrders: Order[] = [];
        // for some reason filter method doesn't work
        // RangeError: Maximum call stack size exceeded
        this.forEach((o: Order) => {
            if (validateOrder(o) === false) {
                invalidOrders.push(o);
            }
        });
        return invalidOrders;
    }
    #getSuggestionsByTrip(): SuggestionByTrip {
        const gMap = new Map();
        this.byTrip.forEach((orders, trip) => {
            orders.forEach((order) => {
                let suggestionMap: [string, Suggestion][] = [];
                let sMap = new Map(suggestionMap);
                gMap.set(trip, sMap);
            });
        });

        return gMap;
    }
    constructor(orders: any[]) {
        let ordersEnt;
        if (Array.isArray(orders)) {
            ordersEnt = orders.map((o) => new Order(o as OrderAttrs));
            ordersEnt = ordersEnt.filter((o) => o.trip?.status !== 'CANCELLED');
        }
        const args = ordersEnt ? ordersEnt : [orders];
        super(...(args as Order[]));
        this.invalidOrders = this.#getInvalidOrders();
        this.byTrip = this.#groupByTrip();
        this.suggestionsByTrip = this.#getSuggestionsByTrip();
        this.setTotalPages();
    }
    getOrderById(id: number): Order | undefined {
        return this.find((o) => o.orderId === id);
    }
    getGroupTripInfo = (group: string): TripInfoBase & { id: string } => {
        const orders = this.byTrip.get(group);
        if (orders === undefined) {
            throw new Error(
                `getGroupTripInfo error: No orders in a group ${group}`
            );
        }
        const ordersIterator = orders.values();
        const order: Order = ordersIterator.next().value;
        const trip = order.trip;
        if (trip === undefined) {
            throw new Error(
                `getGroupTripInfo error: No trips found in a ride for group ${group}`
            );
        }
        return {
            startStationName: trip.startStationName,
            destinationStationName: trip.destinationStationName,
            arrivalTime: trip.arrivalTime,
            departureTime: trip.departureTime,
            startStationId: trip.startStationId,
            destinationStationId: trip.destinationStationId,
            id: order.originTripId,
        };
    };
    getPage(number: number): OrdersCollection {
        const start = number * this.pageSize;
        const end = start + this.pageSize;
        return new OrdersCollection(Array.from(this.slice(start, end)));
    }
    setPageSize(size: number): void {
        this.pageSize = size;
        this.setTotalPages();
    }
    setTotalPages(): void {
        this.totalPages = Math.ceil(this.length / this.pageSize);
    }
}
