import LANG_LIST from './langList';
import Storage from '../localStorage/index';

type i18nConfig = {
    defaultLocale: string;
    availableLocales: string[];
};

const DEFAULT_LOCALE = 'en';

class LocaleService {
    private readonly defaultLocale: string;
    private readonly availableLocales: string[];
    private activeLanguage: string;

    constructor(config: i18nConfig) {
        this.defaultLocale = Storage.get(Storage.LANGUAGE) || DEFAULT_LOCALE;
        this.availableLocales = config.availableLocales;
        this.activeLanguage = this.defaultLocale;
    }

    getAvailableLocales() {
        return this.availableLocales;
    }

    getDefaultLocale() {
        return this.defaultLocale;
    }

    setActiveLanguage(lng: string) {
        this.activeLanguage = lng;
        Storage.set(Storage.LANGUAGE, lng);
    }

    async getMessages(lang: string) {
        if (this.availableLocales.includes(lang)) {
            let messages = null;
            try {
                messages = await this.loadMessages(lang);
            } catch (e) {
                console.error(e);
            }
            return messages;
        }
    }

    loadMessages(lang: string) {
        return import(`../../translations/${lang}.json`);
    }
}

const localService = new LocaleService({
    defaultLocale: DEFAULT_LOCALE,
    availableLocales: Object.keys(LANG_LIST),
});

export default localService;
