import { Infobox, Text } from '@flixbus/honeycomb-react';
import OriginalOrderInfo from '../OriginalOrderInfo/OriginalOrderInfo';
import OrderSummary from '../OriginalOrderInfo/OrderSummary';
import { Options } from '../../dataLayer/AppState/types';
import useAppState from '../../dataLayer/AppState/useAppState';
import { useTranslate } from '../../system/i18n/useTranslate';
import OrdersCollection from '../../dataLayer/classes/OrdersCollection';
import Order from '../../dataLayer/classes/Order';
import { CustomSuggestion, Suggestion } from '../../dataLayer/classes/Trip';

export interface SummaryBodyProps {
    orders?: OrdersCollection | Order[];
    selectedOptions: Options;
    lastStep?: boolean;
}

const SummaryBody = ({
    orders = [],
    selectedOptions,
    lastStep,
}: SummaryBodyProps) => {
    const [appState] = useAppState();
    const translate = useTranslate();
    if (orders.length > 2) {
        return <Text>There are {orders.length} orders to rebook</Text>;
    }

    return (
        <>
            {orders.map((order) => {
                const alternative = appState.alternatives?.[order.orderId];
                const customAlternative =
                    appState.customAlternatives?.[order.orderId];

                const mainSuggestion =
                    appState.singleOptions?.[order.orderId].suggestion;

                const suggestion: (Suggestion & CustomSuggestion) | undefined =
                    mainSuggestion || alternative || customAlternative;
                const trip = order.trip;

                let route, breakingPoint, icInfo;
                if (suggestion) {
                    route = {
                        startStationName: suggestion.startStationName,
                        destinationStationName:
                            suggestion.destinationStationName,
                        arrivalTime: suggestion.arrivalTime,
                        departureTime: suggestion.departureTime,
                    };
                    if (
                        suggestion.isCustom &&
                        suggestion.breakingPoint !== undefined
                    ) {
                        breakingPoint = {
                            stationName: suggestion.breakingPoint.stationName,
                            stationId: suggestion.breakingPoint.stationId,
                            departureTime:
                                suggestion.breakingPoint.departureTime,
                        };
                    }
                    icInfo = suggestion.icInfo;
                }
                if (trip === undefined) {
                    return null;
                }
                return (
                    <div key={`${trip?.id}-origin`}>
                        <OriginalOrderInfo
                            //@ts-ignore
                            trip={trip}
                            orderDetails={order.orderDetails}
                            lastStep={lastStep}
                        />
                        {route !== undefined ? (
                            <>
                                <OrderSummary
                                    route={route}
                                    breakingPoint={breakingPoint}
                                    icInfo={icInfo}
                                    passengers={order.orderDetails.passengers}
                                    type="new"
                                />
                            </>
                        ) : (
                            <Infobox appearance="danger" small>
                                {translate('missing-suggestion-data')}
                            </Infobox>
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default SummaryBody;
