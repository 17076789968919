import { useIntl } from 'react-intl';

export type Translate = (arg0: string, arg1?: any) => string;

const useTranslate = (): Translate => {
    const intl = useIntl();

    const translate: Translate = (key: string, param?: any): string => {
        let message =
            // if the param is number consider it as "plural"
            typeof param === 'number'
                ? intl.formatMessage({ id: key }, { itemCount: param })
                : intl.formatMessage({ id: key });

        const regExp = /{(.*?)}/gi;

        // replace the translation with the passed param
        if (param && message) {
            if (typeof param === 'object') {
                message = message.replace(
                    regExp,
                    (match, substr) => param[substr] || match
                );
            } else {
                message = message.replace(regExp, param);
            }
        }

        // @ts-ignore
        return message || `<${key.match(/\.?(\w*)$/i)[1]}>`;
    };

    return translate;
};

export { useTranslate };
