import { FC, Fragment, ReactNode } from 'react';
import { Icon, IconInterconnection } from '@flixbus/honeycomb-icons-react';
import dayjs from 'dayjs';
import { formatMinutes } from '../../utils';
import RideTooltip from './RideTooltip';
import TransferTooltip from './TransferTooltip';
import PaxStatus from '../PaxStatus';
import RideUid from '../RideUid';

import './InterconnectionInfo.scss';
import IcInfo, { Hop } from '../../dataLayer/classes/IcInfo';

export interface InterconnectionInfoProps<T> {
    icInfo?: T;
    selectedStart?: string;
    ticketId: string;
}

const IcGroupWrapper: FC<{
    isGroup?: boolean;
    children?: ReactNode;
}> = ({ isGroup, children }) => {
    if (isGroup) {
        return <div className="rbk-order-box-ic--selected">{children}</div>;
    }
    return <span>{children}</span>;
};

export default function InterconnectionInfo<T extends IcInfo>({
    icInfo,
    selectedStart,
    ticketId,
}: InterconnectionInfoProps<T>) {
    const hopData = icInfo
        ? icInfo
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .reduce((acc, item, index, collection) => {
                  const lastIndex = collection.length - 1;
                  if (index === lastIndex) {
                      return acc;
                  }
                  const nextRide = collection[index + 1];
                  const hop: Hop = {
                      from: Object.assign({}, item),
                      to: Object.assign({}, nextRide),
                      preselected:
                          item.destinationStationId === selectedStart ||
                          acc.at(-1)?.preselected,
                      at: item.destinationStationName,
                      transfer: formatMinutes(
                          dayjs(nextRide.departureTime).diff(
                              item.arrivalTime,
                              'minute'
                          )
                      ),
                  };
                  acc.push(hop);
                  return acc;
              }, [] as Hop[])
        : [];

    return (
        <span className="rbk-order-box-ic">
            {hopData.map((hop, index) => {
                const { at, from, to, transfer, preselected } = hop;
                return (
                    <Fragment key={`${from.rideUuid}_${index}_wrapper`}>
                        {index === 0 ? (
                            <span key={`${from.rideUuid}_${index}_span`}>
                                <PaxStatus
                                    rideUuid={from.rideUuid}
                                    ticketId={ticketId}
                                />
                                <RideTooltip
                                    arrival={from.arrivalTime}
                                    departure={from.departureTime}
                                >
                                    <span>
                                        <RideUid
                                            rideUuid={from.rideUuid}
                                            lineNumber={from.lineNumber}
                                            tripNumber={
                                                from.tripNumber as
                                                    | string
                                                    | undefined
                                            }
                                            rideUid={from.rideUid}
                                            color={from.lineColor}
                                        />
                                    </span>
                                </RideTooltip>
                            </span>
                        ) : null}

                        <IcGroupWrapper isGroup={preselected}>
                            <TransferTooltip at={at} duration={transfer}>
                                <Icon InlineIcon={IconInterconnection} />
                            </TransferTooltip>
                            <PaxStatus
                                rideUuid={to.rideUuid}
                                ticketId={ticketId}
                            />
                            <RideTooltip
                                arrival={to.arrivalTime}
                                departure={to.departureTime}
                            >
                                <span>
                                    <RideUid
                                        rideUuid={to.rideUuid}
                                        lineNumber={to.lineNumber}
                                        tripNumber={
                                            to.tripNumber as string | undefined
                                        }
                                        rideUid={to.rideUid}
                                        color={to.lineColor}
                                    />
                                </span>
                            </RideTooltip>
                        </IcGroupWrapper>
                    </Fragment>
                );
            })}
        </span>
    );
}
