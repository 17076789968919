import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

require('dayjs/locale/en');
require('dayjs/locale/de');
require('dayjs/locale/tr');
require('dayjs/locale/es');
require('dayjs/locale/hi');
