import { ChangeEvent, useEffect } from 'react';
import { ListWrapper, ListWrapperItem } from '@flixbus/honeycomb-react';
import { RideByOrder } from '../types';
import OrderRidesListItem from './OrderRidesListItem';

export interface OrdersListProps {
    rides: RideByOrder[];
    onSelect?: (rideId: RideByOrder['rideId']) => void;
}

export default function OrderRidesList({ rides, onSelect }: OrdersListProps) {
    useEffect(() => {
        const defaultId =
            rides[0].rideId || rides[0].icInfo?.[0].rideUuid || '';
        onSelect && onSelect(defaultId);
    }, [rides, onSelect]);

    function onFormChanged(e: ChangeEvent<HTMLFormElement>) {
        onSelect && onSelect(e.target.value);
    }

    return (
        <form onChange={onFormChanged}>
            <ListWrapper small>
                {rides.map((ride, index) => (
                    <ListWrapperItem key={ride.rideId}>
                        <OrderRidesListItem ride={ride} index={index} />
                    </ListWrapperItem>
                ))}
            </ListWrapper>
        </form>
    );
}
