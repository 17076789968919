// class for working with local storage in browser (common that can use other classes for store some data)
class LocalStorage {
    localStorageSupported: boolean;
    LANGUAGE: string;

    constructor() {
        this.localStorageSupported =
            typeof window['localStorage'] != 'undefined' &&
            window['localStorage'] != null;
        this.LANGUAGE = 'rebookr2.0.language';
    }

    // set value to storage
    set(key: string, item: string) {
        if (this.localStorageSupported) {
            localStorage.setItem(key, item);
        }
    }

    // get one item by key from storage
    get(key: string): string | null {
        if (this.localStorageSupported) {
            return localStorage.getItem(key);
        } else {
            return null;
        }
    }

    // remove value from storage
    remove(key: string) {
        if (this.localStorageSupported) {
            localStorage.removeItem(key);
        }
    }

    // clear storage (remove all items from it)
    clear() {
        if (this.localStorageSupported) {
            localStorage.clear();
        }
    }
}

const Storage = new LocalStorage();

export default Storage;
