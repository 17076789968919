import { TagProps } from '@flixbus/honeycomb-react';
import { ReactElement } from 'react';

export default function FineTags(props: { children: ReactElement<TagProps> }) {
    const { children } = props;
    const { props: compProps } = children;
    const classesArray = ['rbk-fine-tag'];
    if (compProps.extraClasses) {
        classesArray.push(compProps.extraClasses);
    }
    return (
        <children.type {...compProps} extraClasses={classesArray.join(' ')}>
            {compProps.children}
        </children.type>
    );
}
