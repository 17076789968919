import { getUser } from '../../auth';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { tenantId, uniqueId } from '../../auth/authConfig';
import {
    HeaderUserWidget,
    HeaderSubNavigation,
    NavItem,
} from '@flixbus/honeycomb-react';
import LanguageSwitcher from '../LanguageSwitcher';
import { AppHeaderProps } from './Header';

const Widgets = ({ user, onChangeLanguage }: AppHeaderProps) => {
    const userName: string | undefined = getUser()?.profile?.name || user;
    const activeUser: string = userName ? userName.split(' ')[0] : '';

    const { instance } = useMsal();

    const logOutClickHandler = (instance: IPublicClientApplication) => {
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
        const homeAccountId: string = uniqueId + '.' + tenantId;
        const logoutRequest = {
            account: instance.getAccountByHomeId(homeAccountId),
            mainWindowRedirectUri: window.location.origin,
            postLogoutRedirectUri: '/',
        };
        instance.logoutPopup(logoutRequest);
    };

    const userNavigation = (
        <HeaderSubNavigation id="user-menu">
            <NavItem
                href="#"
                onClick={(e: any) => {
                    e.preventDefault();
                    logOutClickHandler(instance);
                }}
            >
                Logout
            </NavItem>
            <NavItem
                Elem="button"
                onClick={(e: any) => {
                    e.preventDefault();
                }}
            >
                <LanguageSwitcher onChangeLanguage={onChangeLanguage} />
            </NavItem>
        </HeaderSubNavigation>
    );

    return (
        <>
            {activeUser && (
                <HeaderUserWidget subMenu={userNavigation}>
                    {activeUser}
                </HeaderUserWidget>
            )}
        </>
    );
};

export default Widgets;
