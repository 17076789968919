import { Icon, IconTicketSolid } from '@flixbus/honeycomb-icons-react';
import { Link } from '@flixbus/honeycomb-react';
import useApi from '../../api/useApi';
import { useState, useEffect } from 'react';

export interface TicketDownloadProps {
    orderId: string;
    refetch?: number;
}

export default function TicketDownload({ orderId }: TicketDownloadProps) {
    const [url, setUrl] = useState<string>('');
    const [response, request] = useApi('get', `/order/${orderId}/ticket`);

    const handleOnClick = () => {
        if (url !== '') {
            window.open(url, orderId);
        } else {
            request();
        }
    };

    useEffect(() => {
        if (response && typeof response?.data?.body === 'string') {
            window.open(response.data.body, orderId);
            setUrl(response.data.body);
        }
    }, [orderId, response]);

    return (
        <Link
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: 'pointer' }}
            onClick={handleOnClick}
        >
            <Icon appearance="primary" size={3} InlineIcon={IconTicketSolid} />
        </Link>
    );
}
