import { State, DispatchAction } from './types';
import {
    ALTERNATIVES_ADD,
    ALTERNATIVES_REMOVE,
    OPTION_TOGGLE,
    NOTIFY_SET,
    IS_GHOST_SET,
    GHOST_META_SET,
    SEARCH_ORDER_SET,
    SELECTIONS_CLEAR,
    SELECTED_STOPS_SELECT,
    SELECTED_STOPS_UNSELECT,
    CUSTOM_ALTERNATIVES_ADD,
    CUSTOM_ALTERNATIVES_REMOVE,
    PAX_STATUSES_SET,
    BATCH_ORDER_ADD,
    BATCH_ORDER_REMOVE,
    BATCH_ORDER_TOGGLE,
    OPTION_ADD,
    ALTERNATIVES_ADD_BATCH,
    TRACKER_ADD,
    TRACKER_REMOVE,
    SINGLE_OPTION_SET,
    SINGLE_OPTION_ADD,
    SINGLE_OPTION_REMOVE,
    SINGLE_OPTION_RESET,
    BATCH_OPTION_ADD,
    BATCH_OPTION_REMOVE,
    BATCH_OPTION_RESET,
    SINGLE_OPTION_TOGGLE,
} from './actions';
import { BatchAltTripTracking } from '../../DataDog/actions';

export default function reducer(state: State, action: DispatchAction): State {
    const { type, payload } = action;
    switch (type) {
        case ALTERNATIVES_ADD:
            const { alternatives = {}, ...rest } = { ...state };
            alternatives[payload.orderId] = payload.alternative;
            return { ...rest, alternatives: alternatives };
        case ALTERNATIVES_ADD_BATCH:
            const { alternatives: alternativesAddBatch = {} } = state;

            return {
                ...state,
                alternatives: { ...alternativesAddBatch, ...payload },
            };

        case ALTERNATIVES_REMOVE:
            const { alternatives: altAlternatives } = { ...state };
            altAlternatives && delete altAlternatives[payload.orderId];
            if (
                altAlternatives &&
                Object.entries(altAlternatives).length === 0
            ) {
                const altSate = { ...state };
                delete altSate.alternatives;
                return altSate;
            }
            return { ...state, alternatives: altAlternatives };
        case OPTION_TOGGLE:
            const { options = {} } = { ...state };
            if (
                options[payload.orderId] &&
                options[payload.orderId]?.replaceTripId ===
                    payload.option?.replaceTripId
            ) {
                delete options[payload.orderId];
                return { ...state, options };
            }
            options[payload.orderId] = payload.option;
            return { ...state, options };
        case OPTION_ADD:
            const { options: optionsAdd = {} } = state;

            return { ...state, options: { ...optionsAdd, ...payload } };

        case NOTIFY_SET:
            return { ...state, notify: payload };
        case IS_GHOST_SET:
            return { ...state, isGhost: payload };
        case GHOST_META_SET:
            return { ...state, ghostMeta: payload };
        case SEARCH_ORDER_SET:
            return { ...state, searchOrder: [payload] };
        case SELECTIONS_CLEAR:
            let newState = { ...state };
            delete newState.options;
            delete newState.alternatives;
            delete newState.selectedStops;
            delete newState.customAlternatives;
            return newState;
        case SELECTED_STOPS_SELECT:
            return {
                ...state,
                selectedStops: { ...state.selectedStops, ...payload },
            };
        case SELECTED_STOPS_UNSELECT:
            const { selectedStops = {} } = state;
            if (selectedStops[payload]) {
                delete selectedStops[payload];
            }
            if (Object.entries(selectedStops).length === 0) {
                let newState = { ...state };
                delete newState.selectedStops;
                return { ...newState };
            }
            return {
                ...state,
                selectedStops: { ...selectedStops, ...payload },
            };
        case CUSTOM_ALTERNATIVES_ADD:
            const customAlternatives = { ...state.customAlternatives };
            customAlternatives[payload.orderId] = payload.alternative;
            return { ...state, customAlternatives: customAlternatives };
        case CUSTOM_ALTERNATIVES_REMOVE:
            const { customAlternatives: altCustomAlternatives } = { ...state };
            altCustomAlternatives &&
                delete altCustomAlternatives[payload.orderId];
            if (
                altCustomAlternatives &&
                Object.entries(altCustomAlternatives).length === 0
            ) {
                const altSate = { ...state };
                delete altSate.customAlternatives;
                return altSate;
            }
            return { ...state, customAlternatives: altCustomAlternatives };
        case PAX_STATUSES_SET:
            return { ...state, paxStatuses: payload };
        case BATCH_ORDER_ADD:
            const { batchOrders = [] } = state;
            const ordersToAdd = payload.filter(
                (o: number) => !batchOrders.includes(o)
            );
            if (ordersToAdd.length === 0) {
                return state;
            }
            return { ...state, batchOrders: batchOrders.concat(ordersToAdd) };
        case BATCH_ORDER_REMOVE:
            const { batchOrders: batchOrdersRemove } = state;
            if (batchOrdersRemove === undefined) {
                return state;
            }
            if (!batchOrdersRemove?.length) {
                const stateBatchOrderRemove = { ...state };
                delete stateBatchOrderRemove.batchOrders;
                return stateBatchOrderRemove;
            }

            return {
                ...state,
                batchOrders: batchOrdersRemove.filter(
                    (o) => !payload.include(o)
                ),
            };
        case BATCH_ORDER_TOGGLE:
            const { batchOrders: batchOrdersToggle = [] } = state;

            if (batchOrdersToggle.includes(payload)) {
                const stateBatchOrdersToggle = { ...state };
                let batchOrdersToggleResult = batchOrdersToggle.filter(
                    (o) => o !== payload
                );

                if (batchOrdersToggleResult.length === 0) {
                    delete stateBatchOrdersToggle.batchOrders;
                    return stateBatchOrdersToggle;
                }
                return { ...state, batchOrders: batchOrdersToggleResult };
            }
            return {
                ...state,
                batchOrders: batchOrdersToggle.concat([payload]),
            };
        case TRACKER_ADD:
            const { trackers = [] } = state;
            return { ...state, trackers: trackers.concat([payload]) };
        case TRACKER_REMOVE:
            const trackerRemoveState = { ...state };
            if (payload === undefined) {
                delete trackerRemoveState.trackers;
                return trackerRemoveState;
            }
            if (payload instanceof BatchAltTripTracking) {
                const { trackers = [] } = trackerRemoveState;
                const updTrackers = trackers.filter((t) => t !== payload);
                if (updTrackers.length === 0) {
                    delete trackerRemoveState.trackers;
                } else {
                    trackerRemoveState.trackers = updTrackers;
                }

                return trackerRemoveState;
            }
            return trackerRemoveState;
        case SINGLE_OPTION_SET:
            const { singleOptions: singleOptionsSet = {} } = state;
            return {
                ...state,
                singleOptions: { ...singleOptionsSet, ...payload },
            };
        case SINGLE_OPTION_TOGGLE:
            const { singleOptions = {} } = state;
            const { orderId, option } = payload;
            //add
            if (singleOptions?.[orderId] === undefined) {
                singleOptions[orderId] = payload;
                return {
                    ...state,
                    singleOptions,
                };
            }
            // update
            if (
                singleOptions?.[orderId]?.option.replaceTripId !==
                option.replaceTripId
            ) {
                singleOptions[orderId] = payload;
                return {
                    ...state,
                    singleOptions,
                };
            }
            // delete
            const singleOptionsModify = { ...singleOptions };
            delete singleOptionsModify[orderId];
            return { ...state, singleOptions: singleOptionsModify };
        case SINGLE_OPTION_ADD:
            return {
                ...state,
                singleOptions: {
                    ...state.singleOptions,
                    [payload.orderId]: payload,
                },
            };
        case SINGLE_OPTION_REMOVE:
            const singleOptionsRemoveState = { ...state };
            if (singleOptionsRemoveState.singleOptions === undefined) {
                return state;
            }
            delete singleOptionsRemoveState.singleOptions[payload.orderId];

            if (
                Object.keys(singleOptionsRemoveState.singleOptions).length === 0
            ) {
                delete singleOptionsRemoveState.singleOptions;
            }

            return singleOptionsRemoveState;
        case SINGLE_OPTION_RESET:
            const singleOptionsResetState = { ...state };
            delete singleOptionsResetState.singleOptions;
            return singleOptionsResetState;
        case BATCH_OPTION_ADD:
            return {
                ...state,
                batchOptions: {
                    ...state.batchOptions,
                    [payload.group]: payload,
                },
            };
        case BATCH_OPTION_REMOVE:
            const batchOptionsRemoveState = { ...state };
            if (batchOptionsRemoveState.batchOptions === undefined) {
                return state;
            }
            delete batchOptionsRemoveState.batchOptions[payload.group];
            return batchOptionsRemoveState;
        case BATCH_OPTION_RESET:
            const batchOptionsResetState = { ...state };
            delete batchOptionsResetState.batchOptions;
            return batchOptionsResetState;
    }
    return state;
}
