import BatchOrderSummary from '../OriginalOrderInfo/BatchOrderSummary';
import useAppState from '../../dataLayer/AppState/useAppState';
import OrdersCollection from '../../dataLayer/classes/OrdersCollection';
import Order from '../../dataLayer/classes/Order';
import { Suggestion, CustomSuggestion } from '../../dataLayer/classes/Trip';
import { Route } from '../OriginalOrderInfo/types';

export interface BatchSummaryBodyProps {
    orders?: OrdersCollection | Order[];
    lastStep?: boolean;
}

const BatchSummaryBody = ({ orders = [] }: BatchSummaryBodyProps) => {
    const [appState] = useAppState();
    const { batchOptions = {} } = appState;

    function renderBody() {
        return Object.entries(batchOptions).map(([group, option]) => {
            const { orderIds: optionOrders } = option;
            const suggestion: Suggestion & CustomSuggestion = option.suggestion;
            const ordersPerGroup = orders.filter((o) =>
                optionOrders.includes(o.orderId)
            );

            const trip = ordersPerGroup[0]?.trip;

            let route, breakingPoint;
            if (suggestion) {
                route = {
                    rideUid: suggestion.rideUid,
                    startStationName: suggestion.startStationName,
                    destinationStationName: suggestion.destinationStationName,
                    arrivalTime: suggestion.arrivalTime,
                    departureTime: suggestion.departureTime,
                    capacity: suggestion.capacity,
                    rides: suggestion.icInfo,
                };

                if (
                    suggestion.isCustom &&
                    suggestion.breakingPoint !== undefined
                ) {
                    breakingPoint = {
                        stationName: suggestion.breakingPoint.stationName,
                        stationId: suggestion.breakingPoint.stationId,
                        departureTime: suggestion.breakingPoint.departureTime,
                    };
                }
            }
            if (trip === undefined) {
                return null;
            }

            return (
                <div className="batch-summary-body" key={`${trip.uid}-origin`}>
                    <BatchOrderSummary
                        originTrip={trip as Route}
                        newTrip={route as Route}
                        orders={ordersPerGroup as OrdersCollection}
                        breakingPoint={breakingPoint}
                    />
                </div>
            );
        });
    }

    return <>{renderBody()}</>;
};

export default BatchSummaryBody;
