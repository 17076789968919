import { useHistory } from 'react-router-dom';
import { StatusList } from '../RebookStatus';
import { SubPage, SubPageContent } from '../SubPage';

export interface RebookQueueProps {
    rideUuid: string;
}

export default function RebookQueue({ rideUuid }: RebookQueueProps) {
    const history = useHistory();
    return (
        <SubPage
            title="Status queue"
            onClose={() => {
                history.goBack();
            }}
        >
            <SubPageContent>
                <StatusList rideUuid={rideUuid} refetch={20000} />
            </SubPageContent>
        </SubPage>
    );
}
