import { useTranslate } from '../../system/i18n/useTranslate';
import { Product } from '../types';
import { FineTags } from '../HelpersModifiers';
import { Tag } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconBike,
    IconComponent,
    IconLuggageAdditional,
    IconLuggageBulky,
    IconSeat,
    IconSeatFree,
    IconWheelchair,
} from '@flixbus/honeycomb-icons-react';

const ICONS: { [key: string]: IconComponent } = {
    bike_slot: IconBike,
    premium_seat: IconSeat,
    empty_seat: IconSeatFree,
    luggage_bulky: IconLuggageBulky,
    luggage_additional: IconLuggageAdditional,
    wheelchair: IconWheelchair,
};

export default function ProductsTag({
    products = [],
}: {
    products?: Product[];
}) {
    const translate = useTranslate();
    const pMap = new Map<string, { title: string; count: number }>();

    products.forEach(({ name }) => {
        if (pMap.has(name)) {
            let p = pMap.get(name);
            if (p === undefined) {
                return;
            }

            p.count += 1;
            pMap.set(name, p);
        } else {
            pMap.set(name, {
                title: translate(`ancillaries.${name.toLowerCase()}`),
                count: 1,
            });
        }
    });

    return (
        <>
            {Array.from(pMap).map(([key, value]) => {
                return (
                    <FineTags key={key}>
                        <Tag display="outlined" small>
                            <Icon
                                appearance="primary"
                                InlineIcon={ICONS[key.toLowerCase()]}
                            />{' '}
                            {value.title}
                            {value.count > 1 && ` - ${value.count}`}
                        </Tag>
                    </FineTags>
                );
            })}
        </>
    );
}
