import {
    Button,
    Panel,
    PanelHeader,
    PanelContent,
    PanelFooter,
    PanelFooterColumn,
    Text,
    Heading,
} from '@flixbus/honeycomb-react';
import InterconnectionPanelContent from './InterconnectionPanelContent';
import { Translate, useTranslate } from '../../system/i18n/useTranslate';
import { IcRide } from '../../dataLayer/classes/IcInfo';

interface InterconnectionPanelProps<T> {
    rides: T[];
    isOpened: boolean;
    togglePanel: () => void;
    duration?: string;
    title?: string;
    panelTitle: string;
    confirmButtonTitle?: string;
    onConfirmButtonClick?: () => void;
    originTicketId: string;
}

const InterconnectionPanel = <T extends IcRide>({
    isOpened,
    rides,
    togglePanel,
    title,
    duration,
    confirmButtonTitle,
    onConfirmButtonClick,
    panelTitle,
    originTicketId,
}: InterconnectionPanelProps<T>) => {
    const translate: Translate = useTranslate();

    return (
        <Panel
            id={`${originTicketId}-ic-panel`}
            active={isOpened}
            hasOverlay
            onOverlayClick={togglePanel}
        >
            <PanelHeader
                closeButtonProps={{ label: 'Close', onClick: togglePanel }}
            >
                {panelTitle}
            </PanelHeader>
            <PanelContent>
                <Heading flushSpace size={4} extraClasses="rbk-ic-panel__date">
                    {title || <></>}
                </Heading>
                {duration ? (
                    <Text extraClasses="rbk-ic-panel__duration">
                        {translate('duration')}: {duration}
                    </Text>
                ) : null}
                <InterconnectionPanelContent
                    rides={rides}
                    originTicketId={originTicketId}
                />
            </PanelContent>
            <PanelFooter>
                <PanelFooterColumn>
                    {confirmButtonTitle ? (
                        <Button
                            appearance="secondary"
                            display="block"
                            onClick={onConfirmButtonClick || togglePanel}
                        >
                            {confirmButtonTitle}
                        </Button>
                    ) : (
                        <></>
                    )}
                </PanelFooterColumn>
            </PanelFooter>
        </Panel>
    );
};

export default InterconnectionPanel;
