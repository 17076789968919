import { FC, ReactNode } from 'react';
import { Tooltip } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconAttention,
    IconCheckmarkStrong,
} from '@flixbus/honeycomb-icons-react';
import { useTranslate } from '../../system/i18n/useTranslate';

interface RebookingStatusTooltipProps {
    status: 'SUCCESS' | 'ERROR' | 'PENDING';
}
const RebookingStatusTooltip: FC<RebookingStatusTooltipProps> = ({
    status,
}) => {
    const translate = useTranslate();
    let tooltipText: ReactNode = '';
    let StatusIcon = IconAttention;

    if (status === 'SUCCESS') {
        tooltipText = translate('order-status-tooltip.success');
        StatusIcon = IconCheckmarkStrong;
    } else if (status === 'PENDING') {
        tooltipText = translate('order-status-tooltip.pending');
    } else if (status === 'ERROR') {
        tooltipText = translate('order-status-tooltip.error');
    }

    return (
        <Tooltip
            id="status-tooltip"
            content={tooltipText}
            openOnHover
            size="content-fit"
        >
            <Icon
                extraClasses="rbk-order-box__tooltip"
                InlineIcon={StatusIcon}
                style={{ fill: 'red' }}
            />
        </Tooltip>
    );
};

export default RebookingStatusTooltip;
