import { LangsList } from './interface';

const LANG_LIST: LangsList = {
    en: {
        code: 'en',
        shortTitle: 'En',
        title: 'English',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/us.min.svg',
    },
    tr: {
        code: 'tr',
        shortTitle: 'Tr',
        title: 'Türk',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/tr.min.svg',
    },
    de: {
        code: 'de',
        shortTitle: 'De',
        title: 'Deutsch',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/de.min.svg',
    },
    es: {
        code: 'es',
        shortTitle: 'Spa',
        title: 'Español',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/es.min.svg',
    },
    hi: {
        code: 'hi',
        shortTitle: 'Hin',
        title: 'हिंदी(Hindi)',
        icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/in.min.svg',
    },
};

export default LANG_LIST;
