import { Tooltip } from '@flixbus/honeycomb-react';
import { ReactNode } from 'react';
import { formatDateTime, SHORT_TIME_DATE_FORMAT } from '../../utils/index';

export interface RideTooltipProps {
    arrival?: string;
    departure?: string;
    children?: ReactNode;
}

export default function RideTooltip({
    arrival,
    departure,
    children,
}: RideTooltipProps) {
    const depArrTooltip = () => {
        return (
            <>
                {departure ? (
                    <span className="rbk-ic-ride-info-tooltip">
                        <strong>Departure: </strong>
                        {formatDateTime(
                            departure,
                            SHORT_TIME_DATE_FORMAT,
                            true
                        )}
                    </span>
                ) : null}
                {arrival ? (
                    <span>
                        <strong>Arrival: </strong>
                        {formatDateTime(arrival, SHORT_TIME_DATE_FORMAT, true)}
                    </span>
                ) : null}
            </>
        );
    };
    return (
        <Tooltip
            id={`${arrival}-${departure}-tooltip`}
            openOnHover
            size="content-fit"
            position="top"
            /* @ts-ignore */
            content={depArrTooltip()}
        >
            {children}
        </Tooltip>
    );
}
