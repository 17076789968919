import {
    QueryFunctionContext,
    useQuery,
    QueryObserverOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import { api } from '../api';
import { StatusResponse } from '../components/types';

function getStatus({
    queryKey,
}: QueryFunctionContext): Promise<StatusResponse> {
    return api
        .get('/replaceTripQueue/status', {
            params: { rideUuid: queryKey[1] },
        })
        .then((response) => response.data)
        .catch((e) => {
            if (e.response.data?.error && e.response.data?.errorMsg) {
                throw new Error(
                    `${e.response.data.error}: ${e.response.data.errorMsg}`
                );
            }
            throw new Error(`${e.code}: ${e.message}`);
        });
}
export default function useStatus(
    rideUuid: string,
    options: QueryObserverOptions<StatusResponse> = {}
): UseQueryResult<StatusResponse> {
    return useQuery({
        queryKey: ['status', rideUuid],
        queryFn: getStatus,
        ...options,
    });
}
