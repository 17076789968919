import { ReactNode } from 'react';
import Section from './Section';

export interface InfosStripProps {
    children: ReactNode;
    plain?: boolean;
}

export default function InfoStrip({ children, plain }: InfosStripProps) {
    const cssClass = `rbk-info-strip${plain ? ' rbk-info-strip--plain' : ''}`;
    return (
        <section className={cssClass}>
            <Section>{children}</Section>
        </section>
    );
}
