import {
    Icon,
    IconBus,
    IconArrowRight,
    IconArrowDown,
} from '@flixbus/honeycomb-icons-react';
import { Tag } from '@flixbus/honeycomb-react';

import { useTranslate, Translate } from '../../system/i18n/useTranslate';

import InterconnectionPanel from '../InterconnectionPanel/InterconnectionPanel';
import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { IcRide } from '../../dataLayer/classes/IcInfo';

export interface OptionICProps {
    rides: IcRide[];
    originTicketId: string;
}

export default function OptionIC({ rides, originTicketId }: OptionICProps) {
    const [isOpen, setPanelState] = useState<boolean>(false);

    const togglePanel = useCallback(() => {
        setPanelState(!isOpen);
    }, [setPanelState, isOpen]);
    const translate: Translate = useTranslate();

    return (
        <>
            <Tag
                display="outlined"
                extraClasses="rbk-option-ic__tag"
                Elem="button"
                onClick={togglePanel}
            >
                <Icon size={3} InlineIcon={IconBus} />
                <Icon size={2} InlineIcon={IconArrowRight} />
                <Icon size={3} InlineIcon={IconBus} />
                &nbsp;|&nbsp;{rides.length - 1} {translate('transfer')}
                <Icon size={2} InlineIcon={IconArrowDown} />
            </Tag>
            {isOpen &&
                createPortal(
                    <InterconnectionPanel
                        panelTitle="Interconnection Info"
                        isOpened={isOpen}
                        togglePanel={togglePanel}
                        rides={rides}
                        originTicketId={originTicketId}
                    />,
                    document.body
                )}
        </>
    );
}
