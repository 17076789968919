import React from 'react';
import { Language } from '../../system/i18n/interface';

interface ListItemProps {
    language: Language;
    onClick: (lang: string) => void;
}

const ListItem: React.FC<ListItemProps> = ({ language, onClick }) => {
    return (
        <li
            className="language-switcher__item"
            key={language.code}
            onClick={() => {
                onClick(language.code);
            }}
        >
            <span className="language-switcher__lng-text">
                {language.icon ? (
                    <img
                        src={language.icon}
                        className="language-switcher__img"
                        alt={language.shortTitle}
                    />
                ) : null}
                {language.title}
            </span>
        </li>
    );
};

export default ListItem;
