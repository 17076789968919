import { Box, Tag, Tooltip } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconTicket,
    IconSeat,
    IconBike,
    IconRebookSolid,
    IconAttention,
} from '@flixbus/honeycomb-icons-react';
import dayjs from 'dayjs';
import {
    parseTimezone,
    getDiffReadableString,
    FULL_DATE_TIME_FORMAT,
    TIME_ONLY_FORMAT,
} from '../../utils';
import DurationAndDifference from '../DurationAndDifference';

import './OriginalOrderInfo.scss';

import type { OrderDetails, DurationDifference } from '../types';
import InterconnectionInfo from '../InterconnectionInfo/InterconnectionInfo';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';
import ProductsTag from '../ProductsTag';

import Trip, { Suggestion } from '../../dataLayer/classes/Trip';

type PartialOne<T, U extends keyof T> = Omit<T, U> & Pick<Partial<T>, U>;

type OriginalOrderInfoProps = {
    originalTrip?: Trip;
    orderDetails: OrderDetails;
    durationDiff?: DurationDifference;
    diff?: string;
    type?: 'origin' | 'new';
    trip: PartialOne<Trip, 'icInfo'> &
        PartialOne<
            Suggestion,
            | 'gettingStatus'
            | 'suggestionTripType'
            | 'gettingMessage'
            | 'uid'
            | 'capacity'
        >;
    selectedStop?: string;
};

const OriginalOrderInfo = ({
    trip,
    originalTrip,
    orderDetails,
    type = 'origin',
    selectedStop,
}: OriginalOrderInfoProps) => {
    const translate: Translate = useTranslate();

    /** @todo
     * create logic to provide IC info from Suggestion option rides[]
     * to show it in a new card
     */
    const {
        startStationName,
        destinationStationName,
        arrivalTime,
        departureTime,
        capacity,
        icInfo,
        arrivalNextDays,
    } = trip;

    const departureText =
        departureTime === null
            ? '--'
            : dayjs
                  .utc(departureTime)
                  .utcOffset(parseTimezone(departureTime))
                  .format(FULL_DATE_TIME_FORMAT);
    const arrivalText =
        arrivalTime === null
            ? '--'
            : dayjs
                  .utc(arrivalTime)
                  .utcOffset(parseTimezone(arrivalTime))
                  .format(TIME_ONLY_FORMAT);

    const passengers: String[] | null = orderDetails?.passengers?.length
        ? orderDetails.passengers.map(
              (passenger) => `${passenger.name} ${passenger.lastName}`
          )
        : null;
    const products = orderDetails?.products;
    const ticketIcon =
        type === 'origin' ? (
            <Icon InlineIcon={IconTicket} />
        ) : (
            <Icon InlineIcon={IconRebookSolid} extraClasses="rbk-icon--green" />
        );
    const title =
        type === 'origin' ? translate('original_ride') : translate('new_ride');

    let originalDeparture: string =
            type !== 'origin' && originalTrip ? originalTrip.departureTime : '',
        originalArrival: string =
            type !== 'origin' && originalTrip ? originalTrip.arrivalTime : '';
    // const IcInfoFromRides =
    //     rides === undefined
    //         ? undefined
    //         : rides.map((r, index): IcInfo => {
    //               return {
    //                   sortOrder: index,
    //                   rideUuid: r.rideUuid,
    //                   lineNumber: r.rideUid.split(' | ')[0],
    //                   tripNumber: r.rideUid.split(' | ')[1],
    //                   startStationName: r.startStationName,
    //                   destinationStationName: r.destinationStationName,
    //                   departureTime: r.departureTime,
    //                   arrivalTime: r.arrivalTime,
    //                   lineColor: r.lineColor,
    //                   startStationId: r.startStationId,
    //                   destinationStationId: r.destinationStationId,
    //                   rideUid: r.rideUid,
    //               };
    //           });

    const diff = getDiffReadableString(arrivalTime, departureTime);

    const originTicketId: string =
        orderDetails.passengers.length > 0
            ? orderDetails.passengers[0].ticketId || ''
            : '';

    return (
        <Box extraClasses={`order-info order-info--${type}`}>
            <div className="order-info__icon">{ticketIcon}</div>
            <div className="order-info__main">
                <div className="order-info__wrapper">
                    <strong>{title}: </strong>
                    {startStationName} → {destinationStationName}
                    <br />
                    {departureText} - {arrivalText}
                    {arrivalNextDays && <sup>{arrivalNextDays}</sup>}
                    {diff ? ` (${diff})` : null}
                    {icInfo?.length ? (
                        <>
                            <br />
                            <strong>Interconnections: </strong>
                            <InterconnectionInfo
                                icInfo={icInfo}
                                selectedStart={selectedStop}
                                ticketId={originTicketId}
                            />
                        </>
                    ) : null}
                    {/* {IcInfoFromRides?.length ? (
                        <>
                            <br />
                            <strong>Interconnections: </strong>
                            <InterconnectionInfo
                                icInfo={IcInfoFromRides}
                                selectedStart={selectedStop}
                                ticketId={originTicketId}
                            />
                        </>
                    ) : null} */}
                    {passengers ? (
                        <>
                            <br />
                            <strong>{translate('passengers')}: </strong>
                            <span data-dd-privacy="mask">
                                {passengers.join(', ')}
                            </span>
                        </>
                    ) : null}
                    {products ? (
                        <div>
                            <ProductsTag products={products} />
                            <Tooltip
                                id="ancillaries warning"
                                openOnHover
                                size="large"
                                position="right"
                                content={translate('ancillaries-warning')}
                            >
                                <Icon
                                    InlineIcon={IconAttention}
                                    appearance="secondary"
                                />
                            </Tooltip>
                        </div>
                    ) : null}
                </div>
                <div className="order-info__addition-info">
                    {type === 'origin' ? null : ( // currently original ride doesn't have capacity data
                        <div className="order-info__tags">
                            {capacity && (
                                <>
                                    <Tag small display="outlined">
                                        <Icon InlineIcon={IconSeat} />
                                        {capacity.seats}
                                    </Tag>

                                    <Tag small display="outlined">
                                        <Icon InlineIcon={IconBike} />
                                        {capacity.slots}
                                    </Tag>
                                </>
                            )}
                        </div>
                    )}

                    {originalArrival && originalDeparture && (
                        <DurationAndDifference
                            arrivalTime={arrivalTime}
                            departureTime={departureTime}
                            originalArrival={originalArrival}
                            originalDeparture={originalDeparture}
                            extraClasses="rbk-option-card__durr-and-diff"
                        />
                    )}
                </div>
            </div>
        </Box>
    );
};

export default OriginalOrderInfo;
