import { getUser } from './index';
import USER_RULES from './userRules';

const useUserCan = (perform: string) => {
    const check = (action: string) => {
        const roles: string[] | null | undefined =
            getUser() && getUser()?.profile && getUser()?.profile?.roles;
        const permissions: string[] = [];

        if (!roles) {
            // user has no role
            return false;
        }

        roles.forEach((role) => {
            // @ts-ignore
            const permPerRole = USER_RULES[role] || []; // use RO permissions if such role does'n exists in a list

            permPerRole.forEach((perm: any) => {
                if (permissions.indexOf(perm) === -1) {
                    permissions.push(perm);
                }
            });
        });

        if (permissions?.length && permissions.includes(action)) {
            // rule is not provided for action
            return true;
        }

        return false;
    };

    return check(perform);
};

export default useUserCan;
