import { useReducer, useCallback, useEffect, useRef } from 'react';
import api from './clientSetup';

const initState = {
    data: null,
    pending: false,
    error: false,
};

const reducer = (state, action) => {
    const { type, data } = action;
    switch (type) {
        case 'data':
            return {
                ...state,
                data,
                pending: false,
                error: false,
            };
        case 'error':
            return { ...state, error: data, pending: false };
        case 'pending':
            return { ...state, pending: data };
        case 'reset':
            return { ...initState };
        default:
            return { ...state };
    }
};

/**
 * Hook to use Api request with
 * Note: this hook already handle request cancellation with effect
 * @func useApi
 * @param {string} method - literal methods `post`, `get` etc.
 * @param {string|function} url - endpoint, related to apiUrl in client options, to request
 * @param {object} [customClient] - data to send in post body or get params
 * @returns {array} - [response object, requestCaller, resetResponse]
 */
export default function useApi(method, url, customClient) {
    const [response, dispatch] = useReducer(reducer, initState);
    const client = customClient || api;
    const abort = useRef(null);

    useEffect(
        () => () => {
            if (typeof abort.current === 'function') {
                abort.current();
            }
        },
        []
    );

    /**
     * memoized function that will call request
     * @name requestCaller
     * @param {...object} args - arguments that will be applied to the request call
     * data, params, options, etc...
     */
    const request = useCallback(
        (data, options = {}) => {
            const controller = new AbortController();
            const config = {
                method,
                url: typeof url === 'function' ? url() : url,
                signal: controller.signal,
            };
            abort.current = controller.abort;

            dispatch({ type: 'pending', data: true });
            return client({ ...config, ...options, data })
                .then((res) => {
                    dispatch({ type: 'data', data: res.data });
                    return res.data;
                })
                .catch((e) => {
                    if (e?.toString().includes('canceled')) {
                        return e;
                    }
                    dispatch({
                        type: 'error',
                        data: e.response.data,
                    });
                })
                .finally(() => {
                    abort.current = null;
                    dispatch({ type: 'pending', data: false });
                });
        },
        [client, url, method]
    );

    const reset = useCallback(() => {
        dispatch({ type: 'reset' });
    }, [dispatch]);
    return [response, request, reset];
}
