const reactQueryConfig = {
    defaultOptions: {
        queries: {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: 0,
        },
        mutations: {
            mutationKey: ['rebook'],
            refetchOnReconnect: false,
            retry: 0,
        },
    },
};
export default reactQueryConfig;
