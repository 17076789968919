import { Tag } from '@flixbus/honeycomb-react';
import {
    getDiffReadableString,
    getTripsDurationDifference,
} from '../../utils/index';
import { DurationDifference } from '../types';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';

import './DurationAndDifference.scss';

export interface DurationAndDifferenceProps {
    arrivalTime: string;
    departureTime: string;
    originalArrival: string;
    originalDeparture: string;
    extraClasses?: string;
}

const DurationAndDifference = ({
    extraClasses = '',
    arrivalTime,
    departureTime,
    originalArrival,
    originalDeparture,
}: DurationAndDifferenceProps) => {
    const translate: Translate = useTranslate();

    // getting time difference earlier or later than original
    const diffFromOriginal: string =
        new Date(originalDeparture).getTime() >
        new Date(departureTime).getTime() // if earlier than original
            ? getDiffReadableString(
                  departureTime,
                  originalDeparture,
                  translate('earlier')
              ) || ''
            : getDiffReadableString(
                  departureTime,
                  originalDeparture,
                  translate('later')
              ) || '';

    const durationDifference: DurationDifference = getTripsDurationDifference(
        {
            arrivalTime,
            departureTime,
        },
        {
            arrivalTime: originalArrival,
            departureTime: originalDeparture,
        },
        translate
    );

    return (
        <div className={`earlier-later-tags ${extraClasses}`}>
            {durationDifference.duration !== '' && (
                <Tag
                    small
                    extraClasses="earlier-later-tags__tag"
                    appearance={
                        durationDifference.isFaster ? 'success' : 'danger'
                    }
                    display="outlined"
                >
                    {durationDifference.duration}
                </Tag>
            )}
            {diffFromOriginal && (
                <Tag
                    small
                    extraClasses="earlier-later-tags__tag earlier-later-tags__tag--diff"
                >
                    {diffFromOriginal}
                </Tag>
            )}
        </div>
    );
};

export default DurationAndDifference;
