import ActiveItem from './ActiveItem';
import ListItem from './ListItem';

import { useIntl } from 'react-intl';
import LANG_LIST from '../../system/i18n/langList';
import type { Language } from '../../system/i18n/interface';

/**
 * @TODO
 * Rebuild component as a sub nav
 */

const defaultIcon =
    'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/global.min.svg';

interface LanguageSwitcherProps {
    onChangeLanguage: (lang: string) => void;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
    onChangeLanguage,
}) => {
    const intl = useIntl();
    const activeLanguage: Language = LANG_LIST[intl.locale];

    return (
        <ul className="language-switcher">
            <li className="language-switcher__container">
                <ActiveItem
                    icon={activeLanguage.icon || defaultIcon}
                    title={activeLanguage.title}
                    text={activeLanguage.shortTitle}
                />
                <ul className="language-switcher__list">
                    {Object.keys(LANG_LIST).map((lngCode) => (
                        <ListItem
                            language={LANG_LIST[lngCode]}
                            key={lngCode}
                            onClick={() => onChangeLanguage(lngCode)}
                        />
                    ))}
                </ul>
            </li>
        </ul>
    );
};

export default LanguageSwitcher;
