import { RideByOrder } from '../types';
import { Connection, ConnectionStop, Radio } from '@flixbus/honeycomb-react';
import { formatDateTime } from '../../utils';
import InterconnectionInfo from '../InterconnectionInfo/InterconnectionInfo';
import RideUid from '../RideUid';
import IcInfo from '../../dataLayer/classes/IcInfo';

export interface OrderRidesListItemProps {
    ride: RideByOrder;
    index: number;
}

export default function OrderRidesListItem({
    ride,
    index,
}: OrderRidesListItemProps) {
    const title = formatDateTime(ride.departureTime, 'ddd, DD MMM YYYY', true);
    const arrivalTime = formatDateTime(ride.arrivalTime, 'HH:mm', true);
    const departureTime = formatDateTime(ride.departureTime, 'HH:mm', true);
    const id = ride.rideId || ride.icInfo?.[0].rideUuid || '';
    const lineDetails = getLineDetails();

    function getLineDetails() {
        if (ride.lineDetails) {
            return {
                lineNumber: ride.lineDetails.code,
                color: ride.lineDetails.color,
                tripNumber: ride.tripNumber,
            };
        }
        return {
            lineNumber: ride.icInfo?.[0].lineNumber,
            color: ride.icInfo?.[0].lineColor,
            tripNumber: ride.icInfo?.[0].tripNumber,
        };
    }
    return (
        <>
            <div className="rbk-option-card__inner">
                <Radio
                    id={`radio-${id}`}
                    aria-label={`Choose ride ${id}`}
                    label=" "
                    value={id}
                    name="rideId"
                    key={`rideId-${id}`}
                    defaultChecked={index === 0}
                />
                <div>
                    <RideUid
                        rideUuid={id}
                        lineNumber={lineDetails.lineNumber}
                        tripNumber={String(lineDetails.tripNumber)}
                        color={lineDetails.color}
                    />
                    <Connection
                        title={title}
                        extraClasses="rbk-option-card__connection"
                    >
                        <ConnectionStop
                            station={ride.startStationName}
                            time={departureTime}
                        />
                        <ConnectionStop
                            station={ride.destinationStationName}
                            time={arrivalTime}
                        />
                    </Connection>
                </div>
            </div>
            {ride.icInfo ? (
                <>
                    <strong>Interconnection: </strong>{' '}
                    <InterconnectionInfo
                        icInfo={ride.icInfo as IcInfo}
                        ticketId=""
                    />
                </>
            ) : null}
        </>
    );
}
