// a -> c (a -> b / b -> c);
// a:c/1:b:b:2
// ============
// a -> d (a -> b / b -> c / c -> d);
// a:d/1:b:b:2:c:c:3
// ============
// output: [a:1:b, b:2:c, c:3:d];

export function parseDirectId(id: string): string {
    const ride: string | undefined = id.match(/direct:(.*)$/)?.[1];
    if (ride === undefined) {
        throw new Error(`[parseDirectId] can't parse ride from uuid: ${id}`);
    }
    const [rideId, fromId, toId] = ride.split(':');
    return `${fromId}:${rideId}:${toId}`;
}

export function parseIcId(id: string): string[] {
    const route: string | undefined = id.match(
        /interconnection:0:(.*:.*)\//
    )?.[1];
    if (route === undefined) {
        throw new Error(`[parseIcId] can't parse route from uuid: ${id}`);
    }
    const [routeFrom, routeTo] = route.split(':');

    const interconnection: string | undefined = id.match(/\/(.*)$/)?.[1];
    if (interconnection === undefined) {
        throw new Error(
            `[parseIcId] can't parse interconnection from uuid: ${id}`
        );
    }
    let ics = interconnection.split(':');
    ics.unshift(routeFrom);
    ics.push(routeTo);
    let result: string[] = [];
    for (let i = 0; i <= ics.length - 2; i = i + 2) {
        const rideId = ics[i + 1];
        const stopFrom = ics[i];
        const stopTo = ics[i + 2];
        result.push(`${stopFrom}:${rideId}:${stopTo}`);
    }
    return result;
}

export function parseIcUuid(uuid: string): string[] {
    const route: string | undefined = uuid.match(/ic:(.*:.*)\//)?.[1];
    if (route === undefined) {
        throw new Error(`[parseIcUuid] can't parse route from uuid: ${uuid}`);
    }
    const [routeFrom, routeTo] = route.split(':');
    const ic: string | undefined = uuid.match(/\/(.*)$/)?.[1];
    if (ic === undefined) {
        throw new Error(`[parseIcUuid] can't parse ic from uuid: ${uuid}`);
    }
    let ics = ic.split(':');
    ics.unshift(routeFrom);
    ics.push(routeTo);
    let result: string[] = [];

    for (let i = 0; i <= ics.length - 3; i = i + 3) {
        const rideId = ics[i + 1];
        const stopFrom = ics[i];
        const stopTo = ics[i + 2];
        result.push(`${stopFrom}:${rideId}:${stopTo}`);
    }

    return result;
}

export function parseDirectUuid(uuid: string): string {
    const ride: string | undefined = uuid.match(/direct:(.*)$/)?.[1];
    if (ride === undefined) {
        throw new Error(
            `[parseDirectUuid] can't parse ride from uuid: ${uuid}`
        );
    }
    const [rideUuid, fromUuid, toUuid] = ride.split(':');
    return `${fromUuid}:${rideUuid}:${toUuid}`;
}

export function stringifyIcUuid(collection: string[]): string {
    const startStop = collection[0].split(':')[0];
    const endStop = collection.at(-1)?.split(':')[2];
    const line = collection.join(':').split(':').slice(1, -1).join(':');
    return `ic:${startStop}:${endStop}/${line}`;
}

export function stringifyIcId(collection: string[]): string {
    const startStop = collection[0].split(':')[0];
    const endStop = collection.at(-1)?.split(':')[2];
    const line = Array.from(
        new Set(collection.join(':').split(':').slice(1, -1))
    ).join(':');
    return `interconnection:0:${startStop}:${endStop}/${line}`;
}
