export interface UserRules {
    admin_rebooking: string[];
    agent_rebooking_na: string[];
    agent_rebooking: string[];
}

const USER_RULES: UserRules = {
    admin_rebooking: ['trip_search'],
    agent_rebooking_na: [],
    agent_rebooking: [],
};

const USER_ROLE_TITLES = {
    admin_rebooking: 'Admin rebooking',
    agent_rebooking_na: 'Agent rebooking NA',
    agent_rebooking: 'Agent rebooking',
};

const USER_ACTION_TITLES = {
    trip_search: 'Trip search',
};

export default USER_RULES;
export { USER_ROLE_TITLES, USER_ACTION_TITLES };
