import { api } from '../api';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { GhostMeta } from './AppState/types';

export interface RideInfo {
    rideId: string;
    departureStationName: string;
    arrivalStationName: string;
    departureTime: string; //"2022-10-28T23:00:00.000+02:00", create exact match format type
    arrivalTime: string; //"2022-10-29T11:15:00.000+02:00", create exact match format type
    lineId: string;
    lineCode: string;
    tripNumber: number;
}

export default function useRideInfo(
    rideUuid: string,
    ghostMeta: GhostMeta | boolean
): UseQueryResult<RideInfo> {
    function fetch(): Promise<RideInfo> {
        // @todo: remove ghost orders support
        const url =
            typeof ghostMeta === 'object'
                ? `rideInfo/${rideUuid}/${ghostMeta.legacyId}/${ghostMeta.orderId}/${ghostMeta.orderItemId}/${ghostMeta.startStationId}/${ghostMeta.destinationStationId}`
                : `rideInfo/${rideUuid}`;
        return api
            .get(url)
            .then((res) => {
                return res.data.body;
            })
            .catch((e) => {
                if (e.response.data?.error && e.response.data?.errorMsg) {
                    throw new Error(
                        `${e.response.data.error}: ${e.response.data.errorMsg}`
                    );
                }
                throw new Error(`${e.code}: ${e.message}`);
            });
    }

    return useQuery(['ride', rideUuid], fetch, {
        enabled: Boolean(rideUuid) && Boolean(ghostMeta),
    });
}
