import { BrowserRouter } from 'react-router-dom';
import {
    Header,
    HeaderBrand,
    HeaderNavigation,
    HeaderWidgets,
    NavItem,
} from '@flixbus/honeycomb-react';
import Widgets from './Widgets';

import logo from './rebookr-logo.svg';

export interface AppHeaderProps {
    user?: string | undefined;
    onChangeLanguage: (lang: string) => void;
}

const RIDE_SEARCH_URL = 'https://rideviewer.flixbus.com/search';
const AUTOCONTROL_URL = 'https://autocontrol.flixbus.com/';
const REBOOKR_MONITOR_URL = 'https://rideviewer.flixbus.com/rebookr-monitor';

const AppHeader = ({ onChangeLanguage, user }: AppHeaderProps) => {
    return (
        <BrowserRouter>
            <Header fixed={false}>
                <HeaderBrand
                    alt="Rebookr service"
                    href="/"
                    src={logo}
                    width="103"
                    height="32"
                    appearance="tall"
                />
                <HeaderNavigation aria-label="Main">
                    <NavItem href={RIDE_SEARCH_URL} target="_blank">
                        Search
                    </NavItem>
                    <NavItem href={AUTOCONTROL_URL} target="_blank">
                        Autocontrol
                    </NavItem>
                    <NavItem href={REBOOKR_MONITOR_URL} target="_blank">
                        RebookR monitor
                    </NavItem>
                </HeaderNavigation>
                <HeaderWidgets>
                    <Widgets user={user} onChangeLanguage={onChangeLanguage} />
                </HeaderWidgets>
            </Header>
        </BrowserRouter>
    );
};

export default AppHeader;
