import { useEffect, useState } from 'react';
import { SingleAltTripTracking } from './actions';

export default function useSingleAltTripTracking(ride: string, order: number) {
    const [tracker, setTracker] = useState<null | SingleAltTripTracking>(null);
    useEffect(() => {
        if (tracker === null) {
            setTracker(new SingleAltTripTracking(ride, order));
        }
    }, [ride, order, setTracker, tracker]);

    return tracker;
}
