import { Fineprint, Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconAttention } from '@flixbus/honeycomb-icons-react';

export interface DisableSuggestionIconProps {
    orderId?: string | number;
    uid: string | undefined;
    isDisabled?: boolean;
    message: string;
    mode?: 'text' | 'icon';
}

export default function DisableSuggestionIcon({
    orderId = 'order',
    uid = 'suggestion',
    isDisabled,
    message,
    mode = 'icon',
}: DisableSuggestionIconProps) {
    if (isDisabled) {
        return mode === 'icon' ? (
            <Tooltip
                id={`${orderId}-${uid}-tooltip`}
                openOnHover
                content={message}
                smartPosition
                position="top"
            >
                <Icon
                    InlineIcon={IconAttention}
                    appearance="primary"
                    style={{ fill: 'red' }}
                />
            </Tooltip>
        ) : (
            <Fineprint>
                <span className="rbk-text--warning">
                    <Icon InlineIcon={IconAttention} appearance="primary" />{' '}
                    {message}
                </span>
            </Fineprint>
        );
    }

    return null;
}
