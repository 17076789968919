import { Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconTransfer } from '@flixbus/honeycomb-icons-react';
import useAppState from '../../dataLayer/AppState/useAppState';

import { PAX_STATUS, PaxResponseByRideUuid } from '../types';

export const getStatusColor: Record<PAX_STATUS, string> = {
    [PAX_STATUS.Checked]: '#136B00', // #31A100
    [PAX_STATUS.Absent]: '#B31414', // #B31414
    [PAX_STATUS.Unchecked]: '#444444', // default
};

export interface PaxStatusProps {
    rideUuid: string | null;
    ticketId: string | null;
    showTextStatus?: boolean;
}

const PaxStatus: React.FC<PaxStatusProps> = ({
    rideUuid,
    ticketId,
    showTextStatus = false,
}) => {
    const [appState] = useAppState();
    const { paxStatuses } = appState;

    const findPaxStatus: PAX_STATUS | undefined =
        paxStatuses && rideUuid && paxStatuses[rideUuid]
            ? paxStatuses[rideUuid].find(
                  (status: PaxResponseByRideUuid) =>
                      status.ticketId === ticketId
              )?.status
            : PAX_STATUS.Unchecked;
    const paxStatus: PAX_STATUS = findPaxStatus || PAX_STATUS.Unchecked;

    return (
        <span className="pax-status">
            <Tooltip
                id={`${rideUuid}-${ticketId}-tooltip`}
                openOnHover
                content={paxStatus}
                size="content-fit"
                position="top"
            >
                <Icon
                    InlineIcon={IconTransfer}
                    style={{ fill: getStatusColor[paxStatus] }}
                />
            </Tooltip>
            {showTextStatus && (
                <span style={{ color: getStatusColor[paxStatus] }}>
                    {paxStatus}
                </span>
            )}
        </span>
    );
};

export default PaxStatus;
