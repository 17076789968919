import { Box, Grid, GridCol, Skeleton } from '@flixbus/honeycomb-react';

function SkeletonBox() {
    return (
        <Box>
            <Grid>
                <GridCol size={4}>
                    <Skeleton height="sm" inline />
                </GridCol>
                <GridCol>
                    <Skeleton height="sm" inline />
                </GridCol>
                <GridCol size={4}>
                    <Skeleton height="sm" inline />
                </GridCol>
                <GridCol>
                    <Skeleton height="sm" inline />
                </GridCol>
            </Grid>
            <Grid justify="right">
                <GridCol size={4}>
                    <Skeleton height="md" />
                </GridCol>
            </Grid>
        </Box>
    );
}

export default function AlternativesSkeleton() {
    return (
        <>
            <SkeletonBox />
            <SkeletonBox />
            <SkeletonBox />
            <SkeletonBox />
        </>
    );
}
