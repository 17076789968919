import dayjs from 'dayjs';
import {
    Connection,
    ConnectionStop,
    ConnectionSlot,
} from '@flixbus/honeycomb-react';
import { FormatMinutes } from '../types';
import {
    formatMinutes,
    humanReadTimeString,
    formatDateTime,
} from '../../utils';

import PaxStatus from '../PaxStatus';
import RideUid from '../RideUid';

import './InterconnectionPanelContent.scss';
import { IcRide } from '../../dataLayer/classes/IcInfo';
import { Fragment } from 'react';
import { IconTransfer } from '@flixbus/honeycomb-icons-react';

interface InterconnectionPanelContentProps<T> {
    rides: T[];
    originTicketId: string;
}

function InterconnectionPanelContent<T extends IcRide>({
    rides,
    originTicketId,
}: InterconnectionPanelContentProps<T>) {
    const getFormattedTime = (time: string): string => {
        return time ? formatDateTime(time, 'HH:mm', true) : '--:--';
    };

    const getFormattedTransferDuration = (idx: number): FormatMinutes => {
        return formatMinutes(
            dayjs(rides[idx + 1].departureTime).diff(
                rides[idx].arrivalTime,
                'minute'
            )
        );
    };

    const getRidePill = (idx: number): JSX.Element => {
        return (
            <RideUid
                rideUuid={rides[idx + 1].rideUuid}
                rideUid={rides[idx + 1].rideUid}
                color={rides[idx + 1].lineColor}
            />
        );
    };

    const breakingPointRideIndex = rides.findIndex(
        (ride: T) => ride.isPassed !== true
    );

    return (
        <Connection
            appearance={breakingPointRideIndex === 0 ? undefined : 'warning'}
        >
            {rides.map((ride, idx) => {
                const transferDuration = rides[idx + 1]
                    ? getFormattedTransferDuration(idx)
                    : null;
                const arrivalTime = getFormattedTime(ride.arrivalTime);
                const departureTime = getFormattedTime(ride.departureTime);
                const cssClassNameStop = ride.isPassed
                    ? 'rbk-ic-panel__stop--greyout'
                    : undefined;
                return (
                    <Fragment key={`${ride.rideUuid}_${idx}`}>
                        <ConnectionStop
                            station={ride.startStationName}
                            time={departureTime}
                            extraClasses={cssClassNameStop}
                            current={idx === breakingPointRideIndex}
                            InlineIcon={IconTransfer}
                        />
                        <ConnectionStop
                            station={ride.destinationStationName}
                            time={arrivalTime}
                            extraClasses={cssClassNameStop}
                        />
                        {transferDuration && (
                            <ConnectionSlot extraClasses="rbk-ic-panel__transfer">
                                <PaxStatus
                                    rideUuid={ride.rideUuid}
                                    ticketId={originTicketId}
                                    showTextStatus
                                />
                                <div className="rbk-ic-panel__transfer-inner">
                                    {`Transfer time ${humanReadTimeString(
                                        transferDuration
                                    )} to `}
                                    {getRidePill(idx)}
                                </div>
                            </ConnectionSlot>
                        )}
                    </Fragment>
                );
            })}
        </Connection>
    );
}

export default InterconnectionPanelContent;
