import { useContext, useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import {
    useMsal,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import { EventMessage, EventType } from '@azure/msal-browser';
import { loginRequest } from '../auth/authConfig';
import msalInstance from '../auth';
import { IntlProvider } from 'react-intl';
import i18n from '../system/i18n/index';
import Storage from '../system/localStorage/index';

import { Grid, GridCol, Button, Heading } from '@flixbus/honeycomb-react';
import { Icon, IconSecuritySolid } from '@flixbus/honeycomb-icons-react';

import { NotificationContext } from '../system/Notification';
import { NotifyMessage } from '../system/Notification/types';
import AppHeader from '../components/Header';

import RideRebooking from '../components/RideRebooking';
import IndexPage from './IndexPage';

import './main.scss';
import dayjs from 'dayjs';
//import { ddErrorLoginFail } from '../DataDog/actions';

const Main = () => {
    const { instance } = useMsal();
    const notify: (arg: NotifyMessage) => void =
        useContext(NotificationContext);

    const login = () => {
        instance
            .loginPopup({ ...loginRequest })
            .then((r) => {
                setUser(r?.account?.name);
            })
            .catch((e) => {
                notify({ type: 'danger', text: `Auth error ${String(e)}` });
            });
    };
    /**
     * @TODO
     * Place language control in separate place
     */
    const [lang, setLang] = useState(i18n.getDefaultLocale());
    const [messages, setMessages] = useState<Record<string, any>>();
    const [user, setUser] = useState<string | undefined>();

    const onChangeLanguage = (locale: string) => {
        setLang(locale);
        dayjs.locale(locale);
        Storage.set(Storage.LANGUAGE, locale);
    };

    useEffect(() => {
        const callback = msalInstance.addEventCallback(
            (message: EventMessage) => {
                if (
                    message.eventType === EventType.LOGIN_FAILURE ||
                    message.eventType === EventType.SSO_SILENT_FAILURE ||
                    message.eventType === EventType.ACQUIRE_TOKEN_FAILURE
                ) {
                    /**
                     * @todo
                     * come up with solution to have some logs not errors
                     * most errors it is a normal auth flow.
                     */
                    // ddErrorLoginFail({
                    //     type: message.eventType,
                    //     message: String(message.error),
                    // });
                    if (
                        String(message.error).search(
                            /popup_window_error|empty_window_error|monitor_window_timeout/
                        ) !== -1
                    ) {
                        notify({
                            type: 'danger',
                            text: 'Login failed. Please check if popups are blocked in a browser. Change settings and try again.',
                        });
                    }
                }
            }
        );
        return () => {
            callback !== null && msalInstance.removeEventCallback(callback);
        };
    }, [notify]);

    useEffect(() => {
        i18n.getMessages(lang).then((data) => {
            setMessages(data);
            dayjs.locale(lang);
        });
    }, [lang]);

    return (
        <>
            {messages && (
                <IntlProvider
                    messages={messages}
                    key={lang}
                    locale={lang}
                    defaultLocale={lang}
                >
                    <AppHeader
                        onChangeLanguage={onChangeLanguage}
                        user={user}
                    />
                    <div className="rb-container">
                        <AuthenticatedTemplate>
                            <Route path="/" exact component={IndexPage} />
                            <Route path="/:rideId" component={RideRebooking} />
                        </AuthenticatedTemplate>

                        <UnauthenticatedTemplate>
                            <Grid justify="center">
                                <GridCol size={3}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Heading size={3}>
                                            <Icon
                                                InlineIcon={IconSecuritySolid}
                                                size={8}
                                            />
                                        </Heading>
                                        <Heading size={4}>
                                            Login to Rebookr
                                        </Heading>
                                        <Button
                                            onClick={login}
                                            aria-label="Login"
                                            appearance="primary"
                                        >
                                            Login
                                        </Button>
                                    </div>
                                </GridCol>
                            </Grid>
                        </UnauthenticatedTemplate>
                    </div>
                </IntlProvider>
            )}
        </>
    );
};

export default Main;
