export const ALTERNATIVES_ADD = 'alternatives:add';
export const ALTERNATIVES_ADD_BATCH = 'alternatives:add:batch';
export const ALTERNATIVES_REMOVE = 'alternatives:remove';

export const CUSTOM_ALTERNATIVES_ADD = 'custom_alternatives:add';
export const CUSTOM_ALTERNATIVES_REMOVE = 'custom_alternatives:remove';

export const OPTION_TOGGLE = 'options:toggle';
export const OPTION_ADD = 'options:add';
export const OPTION_RESET = 'options:reset';

export const NOTIFY_SET = 'notify:set';

export const IS_GHOST_SET = 'is_ghost:set';
export const GHOST_META_SET = 'ghost_meta:set';

export const SEARCH_ORDER_SET = 'search_order:set';

export const SELECTIONS_CLEAR = 'selections:clear';

export const SELECTED_STOPS_SELECT = 'selected_stops:select';
export const SELECTED_STOPS_UNSELECT = 'selected_stops:unselect';

export const PAX_STATUSES_SET = 'pax_statuses:set';

export const BATCH_ORDER_ADD = 'batch_order:add';
export const BATCH_ORDER_REMOVE = 'batch_order:remove';
export const BATCH_ORDER_TOGGLE = 'batch_order:toggle';

export const TRACKER_ADD = 'tracker:add';
export const TRACKER_REMOVE = 'tracker:remove';

export const SINGLE_OPTION_SET = 'single_option:set';
export const SINGLE_OPTION_TOGGLE = 'single_option:toggle';
export const SINGLE_OPTION_ADD = 'single_option:add';
export const SINGLE_OPTION_REMOVE = 'single_option:remove';
export const SINGLE_OPTION_RESET = 'single_option:reset';

export const BATCH_OPTION_SET = 'batch_option:set';
export const BATCH_OPTION_ADD = 'batch_option:add';
export const BATCH_OPTION_REMOVE = 'batch_option:remove';
export const BATCH_OPTION_RESET = 'batch_option:reset';
