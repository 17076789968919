import { useEffect, RefObject } from 'react';

export interface useScrollProps {
    wrapperRef: RefObject<HTMLDivElement>;
    scrollToRef: RefObject<HTMLDivElement>;
}

export default function useScroll({ wrapperRef, scrollToRef }: useScrollProps) {
    useEffect(() => {
        if (wrapperRef?.current && scrollToRef?.current) {
            typeof wrapperRef.current.scrollTo === 'function' &&
                wrapperRef.current.scrollTo({
                    top:
                        scrollToRef.current.offsetTop -
                        wrapperRef.current.offsetTop,
                    behavior: 'smooth',
                });
        }
    }, [wrapperRef, scrollToRef]);

    return null;
}
