import {
    QueryFunctionContext,
    QueryObserverOptions,
    UseQueryResult,
    useQuery,
} from '@tanstack/react-query';
import { api } from '../api';
import { Order as PayloadOrder } from './payloads.types';
import OrdersCollection from './classes/OrdersCollection';

interface Response {
    body: PayloadOrder[];
}

function fetch({ queryKey }: QueryFunctionContext): Promise<OrdersCollection> {
    const [, rideId, orders] = queryKey;
    return api
        .post<Response>('order/info', {
            rideId,
            orderIds: orders,
        })
        .then((res) => new OrdersCollection(res.data.body))
        .catch((e) => {
            if (
                e.response &&
                e.response.data &&
                e.response.data.error &&
                e.response.data.errorMsg
            ) {
                throw e.response.data.error;
            }
            console.error(e.stack);
            throw e;
        });
}

export default function useOrders(
    rideId: string,
    orders: number[],
    options: QueryObserverOptions<
        OrdersCollection,
        { code?: string; message?: string }
    > = {}
): UseQueryResult<OrdersCollection, { code?: string; message?: string }> {
    return useQuery({
        queryKey: ['orders', rideId, orders],
        queryFn: fetch,
        ...options,
    });
}
