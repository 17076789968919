import { ChangeEvent } from 'react';
import { IcInfo } from '../types';
import { Select, SelectOption } from '@flixbus/honeycomb-react';
import { useTranslate } from '../../system/i18n/useTranslate';

interface StartStationSelectorProps {
    icInfo: Pick<IcInfo, 'startStationId' | 'startStationName' | 'sortOrder'>[];
    onSelect?: (stopId: string) => void;
    value?: string;
}

export default function StartStationSelector({
    icInfo,
    onSelect = () => {},
    value = '',
}: StartStationSelectorProps) {
    const translate = useTranslate();
    const options = icInfo
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((ic, index) => {
            return {
                value: index === 0 ? '' : ic.startStationId || '',
                title: ic.startStationName,
            };
        });
    function onChange(e: ChangeEvent<HTMLSelectElement>) {
        onSelect(e.target.value);
    }
    return (
        <Select
            id="selects-part-ic-station"
            label={translate('start-station-selector.title')}
            onChange={onChange}
            value={value}
        >
            {options.map((option) => (
                <SelectOption key={option.value} value={option.value}>
                    {option.title}
                </SelectOption>
            ))}
        </Select>
    );
}
