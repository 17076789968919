import { ReactNode } from 'react';
import dayjs from 'dayjs';
import { Tooltip } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconAttention,
    IconCalendar,
} from '@flixbus/honeycomb-icons-react';
import { ConnectionEntry, SimpleConnection } from '../SimpleConnection';
import { parseTimezone, formatDateTime } from '../../utils';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';

import Duration from '../Duration';
import { BreakingPoint } from '../types';

interface OptionConnectionProps {
    departureTime: string;
    arrivalTime: string;
    startStationName: string;
    destinationStationName: string;
    isCustom?: boolean;
    breakingPoint?: BreakingPoint;
    arrivalNextDays?: string;
}

const StationName = ({
    children,
    tooltip = true,
}: {
    children: ReactNode;
    tooltip?: boolean;
}) => {
    if (tooltip) {
        return (
            <Tooltip
                id="stop-name-tooltip"
                openOnHover
                content={children}
                position="top"
            >
                <span className="rbk-option-card__stop">{children}</span>
            </Tooltip>
        );
    }

    return <span className="rbk-option-card__stop">{children}</span>;
};

export default function OptionConnection({
    departureTime,
    arrivalTime,
    startStationName,
    destinationStationName,
    isCustom = false,
    breakingPoint,
    arrivalNextDays,
}: OptionConnectionProps) {
    const translate: Translate = useTranslate();

    const title = departureTime
        ? formatDateTime(departureTime, 'ddd, DD MMM YYYY', true)
        : '--, -- --- ----';
    const arrivalTimeText = arrivalTime
        ? formatDateTime(arrivalTime, 'HH:mm', true)
        : '--:--';
    const departureTimeText = departureTime
        ? formatDateTime(departureTime, 'HH:mm', true)
        : '--:--';

    const isSameDayOfArrival =
        departureTime && departureTime
            ? dayjs
                  .utc(departureTime)
                  .utcOffset(parseTimezone(departureTime))
                  .isSame(arrivalTime, 'day')
            : true;

    return (
        <SimpleConnection title={title}>
            <ConnectionEntry
                left={departureTimeText}
                right={
                    <StationName>
                        <strong>
                            {isCustom ? (
                                <del>{startStationName}</del>
                            ) : (
                                startStationName
                            )}
                        </strong>
                    </StationName>
                }
            />
            {breakingPoint && (
                <ConnectionEntry
                    left={formatDateTime(
                        breakingPoint.departureTime,
                        'HH:mm',
                        true
                    )}
                    right={
                        <>
                            <Icon
                                InlineIcon={IconAttention}
                                style={{ fill: 'red' }}
                            />{' '}
                            <span className="rbk-icon-alignment">
                                <StationName>
                                    {breakingPoint.stationName}
                                </StationName>
                            </span>
                        </>
                    }
                />
            )}

            <ConnectionEntry
                left={
                    <Duration timeOne={arrivalTime} timeTwo={departureTime} />
                }
                right={
                    isSameDayOfArrival ? undefined : (
                        <StationName tooltip={false}>
                            <Icon InlineIcon={IconCalendar} />
                            &nbsp;
                            <span className="rbk-icon-alignment">
                                {translate('another-day')}:&nbsp;
                                {dayjs(arrivalTime).format('ddd, DD MMM')}
                            </span>
                        </StationName>
                    )
                }
            />
            <ConnectionEntry
                left={
                    <>
                        <strong>{arrivalTimeText}</strong>{' '}
                        {arrivalNextDays && <sup>{arrivalNextDays}</sup>}
                    </>
                }
                right={
                    <StationName>
                        <strong>{destinationStationName}</strong>
                    </StationName>
                }
            />
        </SimpleConnection>
    );
}
