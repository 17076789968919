import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
    Icon,
    IconArrowLeft,
    IconArrowRight,
} from '@flixbus/honeycomb-icons-react';

import './DatePicker.scss';

export interface DatePickerProps {
    /** Start date. Timestamp. Date that is a middle of a set*/
    startDate: number;
    /** Callback for selection date. Timestamp as an argument */
    onSelect: (timestamp: number) => void;
    /** format to show dates */
    format?: string; //'ddd, DD MMM'
    /** The date options number */
    optionsCount?: number;
    /** The timezone utc offset */
    utcOffset?: number;

    /** Set current date from outside */
    setDate?: number;
}

const DatePicker = ({
    startDate,
    onSelect,
    format = 'ddd, DD MMM',
    optionsCount = 3,
    utcOffset = 0,
    setDate,
}: DatePickerProps) => {
    const [active, setActive] = useState<number>(
        dayjs
            .utc(startDate)
            .utcOffset(utcOffset)
            .startOf('day')
            .add(12, 'hour')
            .valueOf()
    );
    const [shiftRange, setShiftRange] = useState<number>(0);

    function shift(direction: 1 | -1): void {
        setShiftRange((range) => {
            return range + direction;
        });
    }

    useEffect(() => {
        if (setDate && setDate !== active) {
            setActive(setDate);
            const diffDays = Math.ceil(
                dayjs(setDate).diff(startDate, 'day', true)
            );
            const daysShift =
                diffDays < 0
                    ? Math.abs(diffDays) + Math.floor(optionsCount / 2)
                    : Math.abs(diffDays) - Math.floor(optionsCount / 2);
            const page = Math.ceil(daysShift / optionsCount);

            setShiftRange(diffDays < 0 ? -page : page);
        }
    }, [setDate, setActive, startDate, optionsCount, active]);

    const getOptions = useCallback((): Array<number> => {
        const date = dayjs.utc(startDate).utcOffset(utcOffset);
        const genIndex = (optionsCount - 1) / 2;
        function createPart(direction: number): Array<number> {
            let steps = [];
            for (let i = 1; i <= genIndex; i++) {
                steps.push(
                    date
                        .add(direction * i + shiftRange * optionsCount, 'day')
                        .startOf('day')
                        .add(12, 'hour')
                        .valueOf()
                );
            }
            return steps;
        }

        return [
            ...createPart(-1),
            date
                .add(0 + shiftRange * optionsCount, 'day')
                .startOf('day')
                .add(12, 'hour')
                .valueOf(),
            ...createPart(1),
        ];
    }, [startDate, shiftRange, optionsCount, utcOffset]);

    return (
        <div className="date-picker-row">
            <button
                className="date-picker-row__button"
                onClick={() => shift(-1)}
            >
                <Icon InlineIcon={IconArrowLeft} />
            </button>
            {getOptions().map((val) => {
                let cssClass = ['date-picker-row__option'];

                if (dayjs(active).isSame(val, 'day')) {
                    cssClass.push('date-picker-row__option--active');
                }
                return (
                    <button
                        className={cssClass.join(' ')}
                        key={val}
                        type="button"
                        onClick={() => {
                            setActive(val);
                            onSelect(val);
                        }}
                    >
                        {dayjs.utc(val).utcOffset(utcOffset).format(format)}
                    </button>
                );
            })}
            <button
                className="date-picker-row__button"
                onClick={() => shift(1)}
            >
                <Icon InlineIcon={IconArrowRight} />
            </button>
        </div>
    );
};

export default DatePicker;
