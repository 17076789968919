import {
    Pager,
    PagerItem,
    PagerArrow,
    Select,
    SelectOption,
} from '@flixbus/honeycomb-react';
import React, { useEffect, useState } from 'react';
import Order from '../../dataLayer/classes/Order';
import { useTranslate } from '../../system/i18n/useTranslate';

export const DEFAULT_PER_PAGE = 20;
const RESULTS_PER_PAGE = [10, 20, 30];

export interface PaginationProps<T> {
    onPageChange?: (page: number) => void;
    onPageSizeChange?: (size: number) => void;
    children: (items: T[]) => React.ReactNode;
    items?: T[];
}

function ordersCount(groups: [key: string, group: Map<any, any>][]): number {
    return groups.reduce((acc, [_, group]) => {
        return acc + group.size;
    }, 0);
}

const Pagination = <T extends [string, Map<number, Order>]>({
    onPageChange = (page: number) => {},
    onPageSizeChange = (size: number) => {},
    children,
    items = [],
}: PaginationProps<T>) => {
    const [pages, setPages] = useState<number[]>([]);
    const [selectedPage, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(DEFAULT_PER_PAGE);
    const translate = useTranslate();

    useEffect(() => {
        setPages(
            Array.from(
                { length: Math.ceil(items.length / size) },
                (_, index) => index
            )
        );
    }, [items, size]);

    const onClick = (page: number) => {
        setPage(page);
        onPageChange(page);
    };

    const onSelect = (size: number) => {
        setPage(0);
        onPageChange(0);
        setSize(size);
        onPageSizeChange(size);
    };

    const pagination = (
        <>
            {pages.map((page: number) => (
                <PagerItem
                    key={page}
                    active={selectedPage === page}
                    onClick={() => {
                        onClick(page);
                    }}
                >
                    {page + 1}
                </PagerItem>
            ))}
        </>
    );

    const startIndex = selectedPage * size;
    const endIndex = startIndex + size;
    const paginatedItems = items.slice(startIndex, endIndex);
    const ordersNumber = ordersCount(paginatedItems);

    const paginationBlock = (
        <>
            {items.length > DEFAULT_PER_PAGE ? (
                <div className="rbk-pagination">
                    <span>
                        {translate('pagination.show-items-text', {
                            orders: ordersNumber,
                            groups: paginatedItems.length,
                        })}
                    </span>
                    <Pager aria-label="Pagination">
                        <PagerArrow
                            aria-label="Previous Page"
                            disabled={selectedPage === 0}
                            side="prev"
                            onClick={() => {
                                if (selectedPage !== 0) {
                                    onClick(selectedPage - 1);
                                }
                            }}
                        />
                        {pagination}
                        <PagerArrow
                            aria-label="Next Page"
                            disabled={selectedPage + 1 === pages.length}
                            side="next"
                            onClick={() => {
                                if (selectedPage + 1 !== pages.length) {
                                    onClick(selectedPage + 1);
                                }
                            }}
                        />
                    </Pager>
                    <Select
                        id="results-on-page"
                        aria-label="results on a page"
                        onChange={(e) => onSelect(Number(e.target.value))}
                        value={size}
                    >
                        {RESULTS_PER_PAGE.map((results: number) => (
                            <SelectOption value={results} key={results}>
                                {results}
                            </SelectOption>
                        ))}
                    </Select>
                </div>
            ) : null}
        </>
    );

    return (
        <>
            {paginationBlock}
            <>
                {typeof children === 'function'
                    ? children(paginatedItems)
                    : children}
            </>
            {paginationBlock}
        </>
    );
};

export default Pagination;
