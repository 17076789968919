import classNames from 'classnames';
import { Button } from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft, IconClose } from '@flixbus/honeycomb-icons-react';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';

import './ModalWrapper.scss';
import { ReactNode, useEffect } from 'react';

type ModalWrapperProps = {
    isShow: boolean;
    title: string;
    onModalClose: () => void;
    children: ReactNode;
};

const ModalWrapper = ({
    isShow,
    title,
    onModalClose,
    children,
}: ModalWrapperProps) => {
    const modalCss = classNames('rbk-modal', {
        'rbk-modal--is-show': isShow,
    });
    const translate: Translate = useTranslate();

    useEffect(() => {
        if (isShow) {
            document.body.classList.add('modal-is-show');
        }

        return () => {
            document.body.classList.remove('modal-is-show');
        };
    }, [isShow]);

    return (
        <div className={modalCss}>
            <div className="rbk-modal__wrapper">
                <div className="rbk-modal__progress-bar"></div>
                <header className="rbk-modal__header">
                    <Button
                        appearance="link"
                        onClick={onModalClose}
                        role="button"
                    >
                        <>
                            <Icon InlineIcon={IconArrowLeft} />
                            {translate('modal_back')}
                        </>
                    </Button>
                    <div>{title}</div>
                    <Button
                        appearance="link"
                        onClick={onModalClose}
                        role="button"
                    >
                        <>
                            <Icon InlineIcon={IconClose} />
                            {translate('modal_cancel')}
                        </>
                    </Button>
                </header>
                <div className="rbk-modal__inner">{children}</div>
            </div>
            <div className="rbk-modal__overlay"></div>
        </div>
    );
};

export default ModalWrapper;
