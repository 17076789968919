enum OrderStatusMessage {
    ALREADY_REBOOKED = 'order-rebooked',
    ORDER_NOT_FOUND = 'order-not-found',
    ORDER_ITEM_NOT_FOUND = 'order-item-not-found',
    ORDER_HAS_NO_TOKEN = 'order-has-no-token',
    ACCESS_TO_ORDER_DENIED = 'order-access-denied',
    REPLACE_TRIP_ALREADY_ADDED = 'order-trip-already-added',
    ROUTE_ITEM_NOT_FOUND = 'order-route-item-not-found',
}

export enum OrderStatus {
    CANCELLED = 'ORDER_WAS_CANCELLED',
    REBOOKED = 'ORDER_ALREADY_REBOOKED',
    REPLACE_TRIP_ALREADY_ADDED = 'REPLACE_TRIP_FOR_THIS_ORDER_ALREADY_ADDED',
    NOT_FOUND = 'ORDER_NOT_FOUND',
    ITEM_NOT_FOUND = 'ORDER_ITEM_NOT_FOUND',
    NO_TOKEN = 'ORDER_HAS_NO_TOKEN',
    ACCESS_DENIED = 'ACCESS_TO_ORDER_DENIED',
    ROUTE_ITEM_NOT_FOUND = 'ROUTE_ITEM_NOT_FOUND',
}

export const mapStatusToMessage: Record<OrderStatus, OrderStatusMessage> = {
    [OrderStatus.CANCELLED]: OrderStatusMessage.ALREADY_REBOOKED,
    [OrderStatus.REBOOKED]: OrderStatusMessage.ALREADY_REBOOKED,
    [OrderStatus.REPLACE_TRIP_ALREADY_ADDED]: OrderStatusMessage.REPLACE_TRIP_ALREADY_ADDED,
    [OrderStatus.NOT_FOUND]: OrderStatusMessage.ORDER_NOT_FOUND,
    [OrderStatus.ITEM_NOT_FOUND]: OrderStatusMessage.ORDER_ITEM_NOT_FOUND,
    [OrderStatus.NO_TOKEN]: OrderStatusMessage.ORDER_HAS_NO_TOKEN,
    [OrderStatus.ACCESS_DENIED]: OrderStatusMessage.ACCESS_TO_ORDER_DENIED,
    [OrderStatus.ROUTE_ITEM_NOT_FOUND]: OrderStatusMessage.ROUTE_ITEM_NOT_FOUND,
}

