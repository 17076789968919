import { formatMinutes } from '../../utils';
import dayjs from 'dayjs';
import Ride, { RideAttr } from './Ride';

export type IcRideAttr = RideAttr & {
    sortOrder?: number;
    isPassed?: boolean;
    lineColor: string;
    rideUid?: string;
};

export interface Hop {
    from: IcRide;
    to: IcRide;
    at: string;
    transfer: [number, number, number];
    preselected?: boolean;
}

export class IcRide extends Ride {
    sortOrder: number;
    isPassed?: boolean;
    lineColor!: string;
    constructor(object: IcRideAttr) {
        super(object);
        Object.assign(this, object);
        this.sortOrder = object.sortOrder || 0;
        this.isPassed = object.isPassed;
        this.lineColor = object.lineColor;
    }
}

export default class IcInfo extends Array<IcRide> {
    transfers: Hop[];
    #genTransferData(): Hop[] {
        return this.sort((a, b) => a.sortOrder - b.sortOrder).reduce(
            (acc, item, index, collection) => {
                const lastIndex = collection.length - 1;
                if (index === lastIndex) {
                    return acc;
                }
                const nextRide = collection[index + 1];
                const hop: Hop = {
                    from: Object.assign({}, item),
                    to: Object.assign({}, nextRide),

                    at: item.destinationStationName,
                    transfer: formatMinutes(
                        dayjs(nextRide.departureTime).diff(
                            item.arrivalTime,
                            'minute'
                        )
                    ),
                };
                acc.push(hop);
                return acc;
            },
            [] as Hop[]
        );
    }

    constructor(...args: IcRideAttr[]) {
        const rides = args.map((r) => new IcRide(r));
        super(...rides);
        this.transfers = this.#genTransferData();
    }
}
