import { Icon, IconDot } from '@flixbus/honeycomb-icons-react';
import { Link, Tag } from '@flixbus/honeycomb-react';

import { RIDEVIEWER_HOST } from '../../config/const';

export interface RideUidProps {
    rideUuid: string;
    rideUid?: string;
    lineNumber?: string;
    tripNumber?: string;
    color?: string | null;
}

const defaultColor: string = '#888888';
const rideViewUrl = `${RIDEVIEWER_HOST}/ride-view/{rideId}`;

export default function RideUid({
    rideUuid,
    rideUid,
    lineNumber,
    tripNumber,
    color,
}: RideUidProps) {
    const normalizeColor =
        color === null ? defaultColor : `#${color?.replace('#', '')}`;
    function getRideUid() {
        if (rideUid) {
            return rideUid;
        }
        if (lineNumber && tripNumber) {
            return `${lineNumber} | ${tripNumber}`;
        }
    }
    return (
        <Tag
            display="outlined"
            small
            extraClasses="rbk-order-box-ic__tag"
            style={{ borderColor: normalizeColor }}
        >
            <Link
                extraClasses="rbk-reset-underline"
                href={rideViewUrl.replace('{rideId}', rideUuid)}
                target="_blank"
            >
                <Icon
                    InlineIcon={IconDot}
                    size={2}
                    style={{
                        fill: normalizeColor,
                    }}
                />{' '}
                <span className="rbk-icon-alignment">{getRideUid()}</span>
            </Link>
        </Tag>
    );
}
