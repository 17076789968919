import { ReactNode } from 'react';
import {
    Icon,
    IconFemale,
    IconInterconnection,
    IconMale,
} from '@flixbus/honeycomb-icons-react';
import {
    Box,
    Grid,
    GridCol,
    Heading,
    Tag,
    Skeleton,
    Link,
    Infobox,
} from '@flixbus/honeycomb-react';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';
import { OrderDetails } from '../types';

import Trip, {
    Suggestion,
    CustomSuggestion,
    SuggestionsCollection,
} from '../../dataLayer/classes/Trip';

import dayjs from 'dayjs';
import { parseTimezone, formatTimezoneColon } from '../../utils';

import './OrderBox.scss';
import OrderTripInfo from './OrderTripInfo';
import OptionsSlot from './OptionsSlot';
import InterconnectionInfo from '../InterconnectionInfo';
import RebookingStatusTooltip from '../RideRebooking/RebookingStatusTooltip';
import { ORDER_BV_URL } from '../../config/const';
import ProductsTag from '../ProductsTag';
import Order from '../../dataLayer/classes/Order';
import { SelectedOption } from '../OptionCard/OptionCard';
import useGroupSuggestions from '../../dataLayer/useGroupSuggestions';
import { useRouteMatch } from 'react-router-dom';

export interface suggestionsByType {
    FULL?: Suggestion[];
    PARTIAL?: Suggestion[];
}

export type OrderAlternatives = Order & {
    fastSuggestionsIds: string[];
};
export interface OrderBoxProps {
    orderId: string | number;
    originTripId?: string;
    trip: Trip;
    orderDetails: OrderDetails;
    suggestions: SuggestionsCollection;
    alternative?: Suggestion;
    customAlternative?: CustomSuggestion;
    rebookById?: Suggestion;
    selected?: string;
    selectedStop?: string;
    onSelect: (arg0: SelectedOption) => void;
    showAlternatives: (order: OrderAlternatives) => void;
    isLoading: boolean;
    rebookingStatus?: 'SUCCESS' | 'PENDING' | 'ERROR';
}

const genderIcons = { male: IconMale, female: IconFemale };

export default function OrderBox(props: OrderBoxProps) {
    const {
        orderId,
        trip,
        orderDetails,
        originTripId = '',
        alternative,
        customAlternative,
        rebookById,
        selected,
        onSelect,
        showAlternatives,
        selectedStop,
        isLoading,
        rebookingStatus,
    } = props;

    const { passengers = [], products = [] } = orderDetails;

    const { arrivalTime = '', departureTime = '', icInfo } = trip || {};
    const paxList: ReactNode = passengers
        .map(({ name, lastName, gender }, idx) => {
            const paxGenderIcon =
                gender === undefined || gender === ''
                    ? undefined
                    : genderIcons[gender];
            return (
                <span key={`${name + lastName + idx}`} data-dd-privacy="mask">
                    <span className="rbk-icon-alignment">
                        {name} {lastName}
                    </span>
                    {paxGenderIcon ? (
                        <Icon appearance="primary" InlineIcon={paxGenderIcon} />
                    ) : null}
                </span>
            );
        })
        .reduce((acc: ReactNode[], item: ReactNode, index: number) => {
            const res = [...acc];
            if (index === 0) {
                return [item];
            }
            return res.concat([', ', item]);
        }, []);

    const isIc = /^(ic|interconnection)/i.exec(originTripId) !== null;

    const translate: Translate = useTranslate();

    const match = useRouteMatch<{ rideId: string }>();

    const {
        data: options,
        isError,
        error,
    } = useGroupSuggestions(
        match.params.rideId,
        [orderId],
        formatTimezoneColon(trip.departureTime),
        [trip.startStationId, trip.destinationStationId],
        { cacheTime: 36000, retryOnMount: false, staleTime: 36000 }
    );

    function getTripInfo() {
        const {
            startStationName,
            destinationStationName,
            departureTime,
            arrivalTime,
            arrivalNextDays,
        } = trip;
        return (
            <OrderTripInfo
                startStationName={startStationName}
                destinationStationName={destinationStationName}
                arrivalTime={arrivalTime}
                departureTime={departureTime}
                arrivalNextDays={arrivalNextDays}
            />
        );
    }
    function onAlternatives(fastSuggestionsIds: string[]) {
        showAlternatives({
            orderId,
            originTripId,
            trip,
            orderDetails,
            fastSuggestionsIds,
        });
    }

    const originTicketId: string =
        passengers.length > 0 ? passengers[0].ticketId || '' : '';

    const formatArrival = dayjs
        .utc(arrivalTime)
        .utcOffset(parseTimezone(arrivalTime));

    const formatDeparture = dayjs
        .utc(departureTime)
        .utcOffset(parseTimezone(departureTime));

    const timeNow = dayjs().utcOffset(parseTimezone(arrivalTime));

    // Check if departure time is > 72 hours in the past
    const departureTimeExceeded =
        formatDeparture > timeNow
            ? false
            : Math.abs(timeNow.diff(formatDeparture, 'hours', true)) > 72;

    // Check if arrival time is > 24 hours in the past
    const arrivalTimeExceeded =
        formatArrival > timeNow
            ? false
            : Math.abs(timeNow.diff(formatArrival, 'hours', true)) > 24;

    // No rebooking allowed if either of these conditions are true
    const noRebookingAllowed = departureTimeExceeded || arrivalTimeExceeded;

    return (
        <Box highlighted extraClasses="rbk-order-box">
            {!isLoading ? (
                <Grid>
                    <GridCol>
                        <Heading
                            size={3}
                            flushSpace
                            extraClasses="rbk-order-box__heading"
                        >
                            <Link
                                href={ORDER_BV_URL.replace(
                                    '{orderId}',
                                    String(orderId)
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                #{orderId}
                            </Link>
                            {rebookingStatus && (
                                <RebookingStatusTooltip
                                    status={rebookingStatus}
                                />
                            )}
                        </Heading>
                        {getTripInfo()}
                        {isIc ? (
                            <Heading
                                size={4}
                                flushSpace
                                extraClasses="rbk-order-box__heading"
                            >
                                {translate('interconnections')}:{' '}
                                <InterconnectionInfo
                                    icInfo={icInfo}
                                    ticketId={originTicketId}
                                />
                            </Heading>
                        ) : null}
                        <Heading
                            size={4}
                            flushSpace
                            extraClasses="rbk-order-box__heading"
                        >
                            {translate('passengers')}:{' '}
                            <span className="rbk-order-box__pax">
                                {paxList}
                            </span>
                        </Heading>
                    </GridCol>
                    <GridCol size={4}>
                        {isIc && (
                            <div className="rbk-justify--right">
                                <Tag appearance="warning" small>
                                    <Icon InlineIcon={IconInterconnection} /> IC
                                </Tag>
                            </div>
                        )}
                        {products && products.length > 0 && (
                            <div className="rbk-justify--right">
                                <ProductsTag products={products} />
                            </div>
                        )}
                    </GridCol>
                </Grid>
            ) : (
                <Grid>
                    <GridCol>
                        <Skeleton height="md" />
                    </GridCol>
                    <GridCol size={3}>
                        <Skeleton height="md" />
                    </GridCol>
                </Grid>
            )}
            {isError && (
                <Infobox appearance="danger" small>
                    Options are not loaded. {String(error)}
                </Infobox>
            )}
            <OptionsSlot
                orderId={orderId}
                originTripId={originTripId}
                originalArrival={arrivalTime}
                originalDeparture={departureTime}
                options={options}
                alternative={alternative}
                customAlternative={customAlternative}
                rebookById={rebookById}
                onSelect={onSelect}
                selected={selected}
                onAlternatives={onAlternatives}
                noData={!trip}
                noRebookingAllowed={noRebookingAllowed}
                arrivalTimeExceeded={arrivalTimeExceeded}
                selectedStop={selectedStop}
                originTicketId={originTicketId}
                isLoading={isLoading}
            />
        </Box>
    );
}
