import { Configuration, PopupRequest } from '@azure/msal-browser';

const isProd = process.env.NODE_ENV !== 'development';
// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: 'e880ea1c-1789-487d-becf-51ba303d899e',
        authority:
            'https://login.microsoftonline.com/d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5',
        redirectUri: isProd ? '/blank' : window.location.origin,
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ['api://e880ea1c-1789-487d-becf-51ba303d899e/Perform.Rebooking'],
};

export const tenantId: string = 'd8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5';
export const uniqueId: string = '1e6532a6-c93e-4813-84ad-bb1d8681abde';
