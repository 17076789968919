import { getDiffReadableString } from '../../utils/index';

export interface TransferProps {
    timeOne: string;
    timeTwo: string;
}

const Transfer = ({ timeOne, timeTwo }: TransferProps) => {
    const diff: string | undefined = getDiffReadableString(timeOne, timeTwo);
    return diff ? <span className="duration">{diff}</span> : null;
};

export default Transfer;
