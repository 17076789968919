import { Switch, Route } from 'react-router-dom';

// MSAL imports
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';

import Main from './pages/Main';

type AppProps = {
    pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
    return (
        <MsalProvider instance={pca}>
            <Pages />
        </MsalProvider>
    );
}

function Pages() {
    return (
        <Switch>
            <Route path="/">
                <Main />
            </Route>
        </Switch>
    );
}

export default App;
