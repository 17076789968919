import { useHistory, useLocation } from 'react-router-dom';
import { Infobox } from '@flixbus/honeycomb-react';
import { SubPage, SubPageContent } from '../SubPage';
import { useTranslate } from '../../system/i18n/useTranslate';
import { useAppState } from '../../dataLayer/AppState';
import { useEffect } from 'react';

export interface RebookErrorProps {
    rideUuid: string;
}

interface LocationState {
    error: any;
}

export default function RebookError({ rideUuid }: RebookErrorProps) {
    const translate = useTranslate();
    const history = useHistory();
    const location = useLocation<LocationState>();
    const { state: locationState } = location;
    const [appState] = useAppState();

    useEffect(() => {
        const { trackers = [] } = appState;
        for (const tracker of trackers) {
            tracker.track('error', { error: String(locationState.error) });
        }
    }, [locationState, appState]);

    return (
        <SubPage
            title="Rebook error"
            onClose={() => {
                history.goBack();
            }}
        >
            <SubPageContent>
                <Infobox aria-label="Rebooking failed" appearance="danger">
                    <p>
                        <b>{translate('rebooking_failed')}.</b>
                    </p>
                    <p>
                        <b>{translate('details')}:</b> {locationState.error}
                    </p>
                </Infobox>
            </SubPageContent>
        </SubPage>
    );
}
