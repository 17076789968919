import { useRef } from 'react';
import { Infobox } from '@flixbus/honeycomb-react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import StatusList from '../RebookStatus/StatusList';
import { SubPage, SubPageContent } from '../SubPage';
import { RebookStatus as RebookStatusType } from '../types';
import { useAppState } from '../../dataLayer/AppState';
import {
    BatchAltTripTracking,
    SingleAltTripTracking,
} from '../../DataDog/actions';

export interface RebookStatusProps {
    rideUuid: string;
}

interface LocationState {
    data: any;
}

export default function RebookStatus({ rideUuid }: RebookStatusProps) {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const { state: locationState } = location;
    const [appState] = useAppState();
    const tracked = useRef(false);

    /**
     * @todo
     * this function is not testable and should be refactored
     * probably remove this data composer to a different place
     * to reduce complexity of this component
     *
     */
    function onRefetch(data: RebookStatusType[]) {
        const { trackers = [] } = appState;
        if (tracked.current === false) {
            for (const tracker of trackers) {
                const trackData = data.reduce(
                    (acc, item) => {
                        const { status, response, orderId } = item;
                        if (
                            (tracker instanceof BatchAltTripTracking &&
                                tracker.orders.includes(Number(orderId))) ||
                            (tracker instanceof SingleAltTripTracking &&
                                tracker.order === Number(orderId))
                        ) {
                            switch (status) {
                                case 'ERROR':
                                    acc.errorCount++;
                                    acc.errors.push(
                                        `${orderId}: ${String(response)}`
                                    );
                                    break;
                                case 'PENDING':
                                    acc.pending++;
                                    break;
                                case 'SUCCESS':
                                    acc.success++;
                            }
                        }
                        return acc;
                    },
                    { errorCount: 0, pending: 0, success: 0, errors: [] } as {
                        errorCount: number;
                        pending: number;
                        success: number;
                        errors: string[];
                    }
                );
                if (trackData.pending === 0) {
                    tracker.track('complete', trackData);
                    tracked.current = true;
                }
            }
        }
    }
    if (!locationState) {
        return <Redirect to={`/${rideUuid}/queue`} />;
    }
    return (
        <SubPage
            title="Rebook status"
            onClose={() => {
                history.goBack();
            }}
        >
            <SubPageContent>
                {locationState.data.statusCode === 'OK' ? (
                    <>
                        <Infobox small appearance="success">
                            {locationState.data.body.length} orders successfully
                            placed on a rebooking queue
                        </Infobox>
                        <StatusList
                            rideUuid={rideUuid}
                            refetch={8000}
                            onRefetch={onRefetch}
                        />
                    </>
                ) : (
                    <Infobox small appearance="warning">
                        Some orders do not placed in a queue
                    </Infobox>
                )}
            </SubPageContent>
        </SubPage>
    );
}
