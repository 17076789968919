import { api } from '../api';
import {
    QueryFunctionContext,
    useQuery,
    QueryObserverOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import OrdersCollection from './classes/OrdersCollection';

type Response = OrdersCollection;

function fetch({ queryKey }: QueryFunctionContext): Promise<Response> {
    const [
        ,
        rideId,
        rebookingOrdersForRide,
        suggestionsDateTime,
        isGhost,
        altRideId,
    ] = queryKey;
    const url: string = isGhost
        ? `suggestions/ride/rebookingOptions/${rideId}`
        : 'suggestions/ride/rebookingOptions';
    return api
        .post(url, {
            rideId: isGhost ? altRideId : rideId,
            rebookingOrdersForRide,
            suggestionsDateTime,
        })
        .then((response) => new OrdersCollection(response.data.body))
        .catch((e) => {
            if (e.response?.data?.error && e.response?.data?.errorMsg) {
                throw new Error(
                    `${e.response?.data.error}: ${e.response?.data.errorMsg}`
                );
            }
            throw new Error(`${e.code}: ${e.message}`);
        });
}

export default function useAlternatives(
    rideUuid: string,
    orders: (number | string)[],
    date: string | number,
    isGhost?: boolean,
    altRideId?: string | number,
    options: QueryObserverOptions<Response> = {}
): UseQueryResult<Response> {
    return useQuery({
        queryKey: ['alternatives', rideUuid, orders, date, isGhost, altRideId],
        queryFn: fetch,
        ...options,
    });
}
