import { ReactNode, useCallback, useContext } from 'react';
import { NotificationContext } from '../../system/Notification/';
import DisableSuggestionIcon from './DisabledSuggestionIcon';

export default function useDisabledSuggestion(
    orderId: string | undefined | number,
    uid: string | undefined,
    isDisabled: boolean | undefined,
    mode?: 'text' | 'icon'
): [ReactNode, () => void] {
    const notify = useContext(NotificationContext);
    const message =
        'This option is not desirable to be selected and will affect settlement.';
    const showNotify = useCallback(() => {
        if (isDisabled) {
            notify({
                type: 'danger',
                text: message,
            });
        }
    }, [isDisabled, notify]);

    return [
        DisableSuggestionIcon({ orderId, uid, isDisabled, message, mode }),
        showNotify,
    ];
}
