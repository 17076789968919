import { useEffect, useState } from 'react';
import { BatchAltTripTracking } from './actions';

export default function useBatchAltTripTracking(
    group: string,
    orders: number[]
) {
    const [tracker, setTracker] = useState<null | BatchAltTripTracking>(null);
    useEffect(() => {
        if (tracker === null) {
            setTracker(new BatchAltTripTracking(group, orders));
        }
    }, [group, orders, setTracker, tracker]);

    return tracker;
}
