import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
    Input,
    Box,
    Heading,
    Grid,
    GridCol,
    FormRow,
    Button,
    Text,
} from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft } from '@flixbus/honeycomb-icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslate } from '../../system/i18n/useTranslate';
import useOrderSearch from '../../dataLayer/useOrderSearch';
import OrderRidesList from './OrderRidesList';

import './OrderSearch.scss';
import { Link, Redirect } from 'react-router-dom';
import { useAppState, ACTIONS } from '../../dataLayer/AppState';
import { mapStatusToMessage, OrderStatus } from './OrderSearch.namespace';

export default function OrderSearchForm() {
    const translate = useTranslate();
    const [{ options, alternatives }, dispatch] = useAppState();
    const [orderId, setOrderId] = useState<string>('');
    const [rideId, setRideId] = useState<string>('');
    const queryClient = useQueryClient();
    const { data, isError, error, isFetching, refetch, remove } =
        useOrderSearch(orderId, { enabled: false, keepPreviousData: false });
    const [validationError, setValidationError] = useState<string>('');

    function onChange(e: ChangeEvent<HTMLInputElement>): void {
        const onlyDigitsValue = e.target.value.replace(/\D/g, '');
        if (onlyDigitsValue.length === 10) {
            setValidationError('');
        } else {
            setValidationError(translate('order-search-minimum-length'));
        }
        setOrderId(onlyDigitsValue);
    }

    useEffect(() => {
        //Clear APP state user selection in case if form loads with back button
        if (options || alternatives) {
            dispatch(ACTIONS.SELECTIONS_CLEAR);
        }
    }, [options, alternatives, dispatch]);

    function search() {
        if (orderId !== '' && orderId.length > 8) {
            dispatch(ACTIONS.SEARCH_ORDER_SET, Number(orderId));
            refetch();
        }
    }

    useEffect(() => {
        const processedErrorMessage = mapStatusToMessage[error as OrderStatus];

        if (error && processedErrorMessage) {
            setValidationError(translate(processedErrorMessage));
            return;
        }

        if (error) {
            setValidationError(String(error));
        }
    }, [error, translate]);

    const resetData = useCallback(() => {
        queryClient.resetQueries(['order', orderId], { exact: true });
        remove();
    }, [orderId, queryClient, remove]);

    return (
        <Grid justify="center">
            <GridCol size={10} md={6} sm={8} lg={5}>
                <Box highlighted>
                    {!data && (
                        <>
                            <Heading
                                className="rbk-text-align--center"
                                size={3}
                            >
                                Rebooking
                            </Heading>
                            <Text extraClasses="rbk-text-align--center">
                                {/* Please enter an Order ID/Booking number to find
                                an order first */}
                                {translate('order-search-text')}
                            </Text>
                            <Grid align="top" justify="center">
                                <GridCol size={6}>
                                    <FormRow>
                                        <Input
                                            id="orderID"
                                            label={translate(
                                                'input-label-orderId'
                                            )}
                                            placeholder="XXXXXXXXXX"
                                            value={orderId}
                                            onChange={onChange}
                                            valid={
                                                !isError && !validationError
                                                    ? undefined
                                                    : false
                                            }
                                            infoError={
                                                validationError || undefined
                                            }
                                            disabled={isFetching}
                                        />
                                    </FormRow>
                                </GridCol>
                                <GridCol size={4}>
                                    <FormRow>
                                        <Button
                                            extraClasses="rbk-order-search__button"
                                            appearance="primary"
                                            onClick={search}
                                            disabled={
                                                isFetching ||
                                                orderId === '' ||
                                                !!validationError
                                            }
                                            loading={isFetching}
                                            display="block"
                                        >
                                            {translate(
                                                'order-search-button-search'
                                            )}
                                        </Button>
                                    </FormRow>
                                </GridCol>
                            </Grid>
                        </>
                    )}
                    {data && data.length === 1 && (
                        <Redirect
                            to={`/${
                                data[0].rideId || data[0].icInfo?.[0].rideUuid
                            }`}
                            push
                        />
                    )}
                    {data && data.length > 1 ? (
                        <>
                            <div className="rbk-active-title">
                                <div className="rbk-active-title__action">
                                    <Button
                                        appearance="link"
                                        onClick={resetData}
                                    >
                                        <Icon InlineIcon={IconArrowLeft} />
                                        {translate('order-search-button-back')}
                                    </Button>
                                </div>
                                <div className="rbk-active-title__text">
                                    Booking Nr {orderId}
                                </div>
                            </div>
                            <Heading
                                className="rbk-text-align--center"
                                size={3}
                            >
                                Rebooking
                            </Heading>
                            <Text extraClasses="rbk-text-align--center">
                                {/* Please select which trip you want to change */}
                                {translate('order-search-select-text')}
                            </Text>
                            <FormRow>
                                <OrderRidesList
                                    rides={data}
                                    onSelect={setRideId}
                                />
                            </FormRow>
                            <Grid justify="center">
                                <GridCol extraClasses="rbk-text-align--center">
                                    <Button
                                        type="button"
                                        appearance="secondary"
                                        RouterLink={Link}
                                        to={`/${rideId}`}
                                    >
                                        {/* Continue */}
                                        {translate(
                                            'order-search-button-continue'
                                        )}
                                    </Button>
                                </GridCol>
                            </Grid>
                        </>
                    ) : null}
                </Box>
            </GridCol>
        </Grid>
    );
}
