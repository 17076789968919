import classNames from 'classnames';
import { Icon, IconArrowDown } from '@flixbus/honeycomb-icons-react';

interface ActiveItemProps {
    icon: string;
    text: string;
    title?: string;
    className?: string;
}

const ActiveItem: React.FC<ActiveItemProps> = ({
    icon,
    text,
    title = '',
    className = '',
}) => {
    const cssClass = classNames('language-switcher__active-lng', className);
    return (
        <span className={cssClass}>
            {icon ? (
                <img
                    src={icon}
                    className="language-switcher__img"
                    alt={title}
                />
            ) : null}
            {text}
            <Icon InlineIcon={IconArrowDown} />
        </span>
    );
};

export default ActiveItem;
