import { useCallback, useContext } from 'react';
import { AppStateContext } from './AppStateProvider';
import { State, Payloads } from './types';

export default function useAppState(): [
    State,
    <T extends keyof Payloads>(action: T, payload?: Payloads[T] | never) => void
] {
    const { state, dispatch } = useContext(AppStateContext);
    const dispatchAction = useCallback(
        function dispatchActionHandler<T extends keyof Payloads>(
            action: T,
            payload?: Payloads[T]
        ): void {
            dispatch({ type: action as string, payload });
        },
        [dispatch]
    );
    return [state, dispatchAction];
}
