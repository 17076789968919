import {
    Grid,
    GridCol,
    Heading,
    Infobox,
    Skeleton,
    Tag,
} from '@flixbus/honeycomb-react';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';
import { Section } from '../Layout';

type OriginalRideInfoProps = {
    lineCode?: string;
    tripNumber?: number;
    showSkeleton?: boolean;
    isGhost?: boolean;
    error?: string;
};

const OriginalRideInfo = ({
    lineCode,
    tripNumber,
    isGhost,
    showSkeleton,
    error,
}: OriginalRideInfoProps) => {
    const translate: Translate = useTranslate();

    if (showSkeleton) {
        return (
            <Grid>
                <GridCol size={2}>
                    <Skeleton height="md" />
                </GridCol>
                <GridCol size={1}>
                    <Skeleton height="md" />
                </GridCol>
                <GridCol size={1}>
                    <Skeleton height="md" />
                </GridCol>
            </Grid>
        );
    }

    if (error) {
        return (
            <Section>
                <Infobox appearance="danger">{error}</Infobox>
            </Section>
        );
    }

    return (
        <Heading size={1}>
            {translate('rebooking_for')} L{lineCode} TN
            {String(tripNumber).padStart(2, '0')}
            &nbsp;
            {isGhost && (
                <Tag small display="outlined">
                    Ghost
                </Tag>
            )}
        </Heading>
    );
};

export default OriginalRideInfo;
