import { ReactNode } from 'react';
import classNames from 'classnames';

export interface SubPageFooterProps {
    children: ReactNode;
    justify?: 'left' | 'right' | 'center';
}

export default function SubPageFooter({
    children,
    justify,
}: SubPageFooterProps) {
    const cssClass = classNames('rbk-sub-page__footer', {
        [`rbk-sub-page__footer--${justify}`]: justify,
    });
    return <div className={cssClass}>{children}</div>;
}
