import { Box, Button, Tooltip } from '@flixbus/honeycomb-react';
import type { Passenger } from '../types';
import {
    Icon,
    IconArrowBigRight,
    IconArrowRight,
    IconAttention,
    IconQuestion,
    IconRebookSolid,
    IconTicket,
} from '@flixbus/honeycomb-icons-react';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';
import {
    FULL_DATE_TIME_FORMAT,
    TIME_ONLY_FORMAT,
    formatDateTime,
    getDiffReadableString,
} from '../../utils';
import InterconnectionInfo from '../InterconnectionInfo';
import DurationAndDifference from '../DurationAndDifference';
import InterconnectionPanel from '../InterconnectionPanel/InterconnectionPanel';
import { useState } from 'react';
import type { Route, BreakingPoint } from './types';
import ProductsTag from '../ProductsTag';
import Trip from '../../dataLayer/classes/Trip';
import IcInfo from '../../dataLayer/classes/IcInfo';

interface OrderSummaryProps {
    route: Route;
    originalTrip?: Pick<Trip, 'arrivalTime' | 'departureTime'>;
    breakingPoint?: BreakingPoint;
    passengers?: Passenger[];
    icInfo?: IcInfo;
    products?: { name: string }[];
    type?: 'new' | 'origin';
}

function OrderSummary({
    route,
    originalTrip,
    breakingPoint,
    passengers,
    icInfo,
    products,
    type = 'origin',
}: OrderSummaryProps) {
    const [isPanelOpen, setPanel] = useState<boolean>(false);
    const translate: Translate = useTranslate();
    const ticketIcon =
        type === 'origin' ? (
            <Icon InlineIcon={IconTicket} />
        ) : (
            <Icon InlineIcon={IconRebookSolid} extraClasses="rbk-icon--green" />
        );
    const title =
        type === 'origin' ? translate('original_ride') : translate('new_ride');
    const departureText =
        route.departureTime === null
            ? '--'
            : formatDateTime(route.departureTime, FULL_DATE_TIME_FORMAT, true);
    const arrivalText =
        route.arrivalTime === null
            ? '--'
            : formatDateTime(route.arrivalTime, TIME_ONLY_FORMAT, true);
    const breakingPointTime = breakingPoint?.departureTime
        ? formatDateTime(
              breakingPoint.departureTime,
              FULL_DATE_TIME_FORMAT,
              true
          )
        : undefined;
    const diff = getDiffReadableString(
        route.arrivalTime,
        breakingPoint?.departureTime || route.departureTime
    );

    const originTicketId: string =
        passengers && passengers.length > 0 ? passengers[0].ticketId || '' : '';
    return (
        <Box extraClasses={`order-info order-info--${type}`}>
            <div className="order-info__icon">{ticketIcon}</div>
            <div className="order-info__main order-info__wrapper">
                <div>
                    <strong>{title}: </strong>
                    {breakingPoint ? (
                        <>
                            <span className="order-info--fade">
                                {route.startStationName}
                            </span>
                            <Icon
                                InlineIcon={IconArrowBigRight}
                                appearance="primary"
                            />
                            {breakingPoint.stationName}
                            <Tooltip
                                id={`${breakingPoint.stationName}-tooltip`}
                                content="Manual selected"
                                openOnHover
                            >
                                <Icon
                                    InlineIcon={IconQuestion}
                                    appearance="secondary"
                                />
                            </Tooltip>
                        </>
                    ) : (
                        route.startStationName
                    )}
                    <Icon InlineIcon={IconArrowBigRight} appearance="primary" />
                    {route.destinationStationName}
                </div>
                <div>
                    {breakingPointTime ? (
                        <>
                            <span className="order-info--fade">
                                {departureText}
                            </span>{' '}
                            - {breakingPointTime}
                        </>
                    ) : (
                        departureText
                    )}{' '}
                    - {arrivalText}
                    {diff ? `(${diff})` : null}{' '}
                </div>
                {breakingPoint && icInfo ? (
                    <>
                        <strong>Interconnections: </strong>{' '}
                        <Button
                            appearance="link"
                            onClick={() => {
                                setPanel(true);
                            }}
                        >
                            More info{' '}
                            <Icon
                                InlineIcon={IconArrowRight}
                                style={{ fill: 'green' }}
                            />
                        </Button>
                        <InterconnectionPanel
                            isOpened={isPanelOpen}
                            originTicketId={originTicketId}
                            togglePanel={() => {
                                setPanel(false);
                            }}
                            panelTitle="Interconnections"
                            rides={icInfo}
                        />
                    </>
                ) : null}

                {icInfo && !breakingPoint ? (
                    <div>
                        <strong>Interconnections: </strong>
                        <InterconnectionInfo icInfo={icInfo} ticketId="" />
                    </div>
                ) : null}

                {passengers ? (
                    <div data-dd-privacy="mask">
                        <strong>{translate('passengers')}: </strong>
                        {passengers
                            .map((p) => `${p.name} ${p.lastName}`)
                            .join(', ')}
                    </div>
                ) : null}
                <div className="order-info__addition-info">
                    {products ? (
                        <span>
                            <ProductsTag products={products} />
                            <Tooltip
                                id="ancillaries warning"
                                openOnHover
                                size="large"
                                position="right"
                                content={translate('ancillaries-warning')}
                            >
                                <Icon
                                    InlineIcon={IconAttention}
                                    appearance="secondary"
                                />
                            </Tooltip>
                        </span>
                    ) : null}
                    {originalTrip && (
                        <DurationAndDifference
                            arrivalTime={route.arrivalTime}
                            departureTime={route.departureTime}
                            originalArrival={originalTrip.arrivalTime}
                            originalDeparture={originalTrip.departureTime}
                            extraClasses="rbk-option-card__durr-and-diff"
                        />
                    )}
                </div>
            </div>
        </Box>
    );
}

export default OrderSummary;
