import { ReactNode } from 'react';

type ConnectionEntryProps = {
    left?: ReactNode;
    right?: ReactNode;
};

export default function ConnectionEntry({ left, right }: ConnectionEntryProps) {
    return (
        <tr>
            <td>
                <div className="rbk-simple-connection__entry--left">{left}</div>
            </td>
            <td>
                <div className="rbk-simple-connection__entry--right">
                    {right}
                </div>
            </td>
        </tr>
    );
}
