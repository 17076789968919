import { Tooltip } from '@flixbus/honeycomb-react';
import { ReactNode } from 'react';
import type { FormatMinutes } from '../types';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';
export interface TransferTooltipProps {
    at: string;
    duration: FormatMinutes;
    children?: ReactNode;
}

export default function TransferTooltip({
    at,
    duration,
    children,
}: TransferTooltipProps) {
    const translate: Translate = useTranslate();
    const transfer = () => {
        const [days, hours, minutes] = duration;
        return (
            <>
                <span className="rbk-ic-ride-info-tooltip">
                    <strong>{translate('transfer')}: </strong>
                    {days > 0 ? `${days}d` : null}{' '}
                    {hours > 0 ? `${hours}h` : null}{' '}
                    {minutes > 0 ? `${minutes}min` : null}
                </span>

                <span className="rbk-ic-ride-info-tooltip">
                    <strong>{translate('at')}: </strong>
                    {at}
                </span>
            </>
        );
    };
    return (
        <Tooltip
            id={`${at}-${duration}-tooltip`}
            openOnHover
            size="content-fit"
            position="top"
            content={transfer()}
        >
            {children}
        </Tooltip>
    );
}
