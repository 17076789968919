import { Table, Link, Divider, Fineprint } from '@flixbus/honeycomb-react';
import { Icon, IconNewTab } from '@flixbus/honeycomb-icons-react';
import dayjs from 'dayjs';
import useStatus from '../../dataLayer/useStatus';
import StatusCell from './StatusCell';
import { TicketDownload } from '../TicketDownload';
import { ORDER_BV_URL, ORDER_BE_URL } from '../../config/const';
import './StatusList.scss';
import { useTranslate } from '../../system/i18n/useTranslate';
import { useEffect } from 'react';
import { RebookStatus } from '../types';

export interface StatusBoxProps {
    rideUuid: string;
    refetch?: number | false;
    onRefetch?: (data: RebookStatus[]) => void;
}

export default function StatusList({
    rideUuid,
    refetch = false,
    onRefetch,
}: StatusBoxProps) {
    const { data, isRefetching, isRefetchError, error } = useStatus(rideUuid, {
        refetchInterval: (data) => {
            if (data === undefined) {
                return refetch;
            }
            const isPending = data.body.some((i) => i.status === 'PENDING');
            return isPending ? refetch : false;
        },
    });
    const translate = useTranslate();
    useEffect(() => {
        if (data !== undefined && data.body.length > 0 && onRefetch) {
            onRefetch(data.body);
        }
    }, [data, onRefetch]);

    if (error) {
        return <>{String(error)}</>;
    }

    if (
        data === undefined ||
        data.statusCode !== 'OK' ||
        data.body.length === 0
    ) {
        return <>Queue is empty</>;
    }
    const headers = [
        translate('status-table.header.order'),
        translate('status-table.header.status'),
        translate('status-table.header.user'),
        translate('status-table.header.date'),
    ];
    const rows = data.body.map((row, idx) => {
        const { orderId, newOrderId, createdBy, createdAt } = row;

        return [
            <div key={orderId + idx}>
                <Link
                    href={ORDER_BV_URL.replace('{orderId}', orderId)}
                    target="_blank"
                    rel="noopener noreferrer"
                    extraClasses="rbk-status__link"
                >
                    {newOrderId ? (
                        <Fineprint>
                            <del>{orderId}</del>
                        </Fineprint>
                    ) : (
                        orderId
                    )}
                </Link>
                {newOrderId ? (
                    <>
                        <Divider />
                        <Link
                            href={ORDER_BV_URL.replace('{orderId}', newOrderId)}
                            target="_blank"
                            rel="noopener noreferrer"
                            extraClasses="rbk-status__link"
                        >
                            {newOrderId}
                        </Link>{' '}
                        <Link
                            href={ORDER_BE_URL.replace('{orderId}', newOrderId)}
                            target="_blank"
                            rel="noopener noreferrer"
                            extraClasses="rbk-status__link"
                        >
                            <Icon InlineIcon={IconNewTab} />
                        </Link>{' '}
                        {newOrderId && <TicketDownload orderId={newOrderId} />}
                    </>
                ) : null}
            </div>,
            <StatusCell item={row} />,
            createdBy,
            dayjs.unix(createdAt).format('HH:mm, DD.MM'),
        ];
    });

    return (
        <>
            <div className="rbk-reserve-line">
                {isRefetchError && String(error)}
            </div>
            <Table
                extraClasses={
                    isRefetching ? 'rbk-status-table--update' : undefined
                }
                headers={headers}
                rows={rows}
            />
        </>
    );
}
