import RideBase, { AttrsBase } from './RideBase';

export type RideAttr = AttrsBase & {
    rideUid?: string;
    lineColor: string;
    lineNumber?: string;
    tripNumber?: number;
    rideUuid: string;
    lineDetails?: {
        id: string;
        code: string;
        cooperation: boolean;
        color: string;
    };
};

export function getLineNumber(object: {
    lineNumber?: string;
    lineDetails?: { code: string };
    rideUid?: string;
}): string {
    if (object.lineNumber) {
        return object.lineNumber;
    }
    if (object.lineDetails) {
        return object.lineDetails.code;
    }
    if (object.rideUid) {
        return object.rideUid.split(' | ')[0];
    }
    return '';
}

export function getRideUid(object: {
    lineNumber?: string;
    lineDetails?: { code: string };
    rideUid?: string;
    tripNumber?: number;
}): string {
    if (object.rideUid) {
        return object.rideUid;
    }
    if (object.lineNumber && object.tripNumber) {
        return `${object.lineNumber} | ${object.tripNumber}`;
    }
    if (object.lineDetails && object.tripNumber) {
        return `${object.lineDetails.code} | ${object.tripNumber}`;
    }
    return '';
}

export default class Ride extends RideBase {
    lineColor!: string;
    lineNumber?: string;
    tripNumber?: number;
    uid?: string;
    rideUid?: string;
    rideUuid!: string;

    constructor(object: RideAttr) {
        super(object);
        Object.assign(this, object);
        this.lineNumber = getLineNumber(object);
        this.tripNumber =
            object.tripNumber ||
            Number(object.rideUid?.split(' | ')[1]) ||
            undefined;
        this.rideUid = getRideUid(object);
        this.lineColor = object.lineColor || object.lineDetails?.color || '';
    }
}
