// MSAL imports
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
    InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { msalConfig, loginRequest } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

export function getUser(): {
    id: string;
    profile?: { name?: string; roles?: string[] };
    userName: string;
} | null {
    if (accounts[0]) {
        return {
            id: accounts[0].localAccountId,
            profile: accounts[0].idTokenClaims,
            userName: accounts[0].username,
        };
    }
    return null;
}

export function getToken() {
    const request = {
        ...loginRequest,
        account: accounts[0],
    };

    return new Promise((resolve, reject) => {
        msalInstance
            .acquireTokenSilent(request)
            .then((response) => {
                resolve(response.accessToken);
            })
            .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    return msalInstance
                        .acquireTokenPopup(request)
                        .then((response) => {
                            resolve(response.accessToken);
                        });
                }

                return e;
            })
            .catch((e) => reject(new Error(`Auth fail with error: ${e}`)));
    });
}

export default msalInstance;
