import React from 'react';

/**
 * Context for Notification system
 * @instance NotificationContext
 *
 */
const NotificationContext = React.createContext((payload) => {});

export default NotificationContext;
