import StopsSelectorAutocomplete from './StopsSelectorAutocomplete';
import useStopsList from '../../dataLayer/useStopsList';
import { useCallback, useState } from 'react';
import { Box } from '@flixbus/honeycomb-react';
import { useTranslate } from '../../system/i18n/useTranslate';
import { AutocompleteStopsList } from '../types';

export default function StopsSelector({
    onSelect,
}: {
    onSelect?: ([from, to]: (
        | Partial<AutocompleteStopsList>
        | undefined
    )[]) => void;
}) {
    const translate = useTranslate();
    const [enabled, setEnabled] = useState(false);
    const {
        data: stopsList,
        isError,
        error,
        isFetching,
    } = useStopsList({ enabled });

    const onFocus = useCallback(() => {
        if (!enabled) {
            setEnabled(true);
        }
    }, [enabled, setEnabled]);

    if (isError) {
        return (
            <Box appearance="warning" small>
                {translate('stops-list.error')}
                {String(error)}
            </Box>
        );
    }

    return (
        <StopsSelectorAutocomplete
            loading={isFetching}
            options={stopsList}
            onFocus={onFocus}
            onSelect={onSelect}
        />
    );
}
