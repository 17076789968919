import { InitTripInfo } from '../types';

import Trip, {
    Suggestion,
    CustomSuggestion,
} from '../../dataLayer/classes/Trip';
import IcInfo, { IcRide } from '../../dataLayer/classes/IcInfo';
import Ride, { RideAttr } from '../../dataLayer/classes/Ride';
import {
    parseDirectUuid,
    parseIcUuid,
    stringifyIcUuid,
} from '../../utils/parseIcUuid';

type CustomRideAttr = RideAttr & {
    isPassed: boolean;
};
export class CustomRide extends Ride {
    isPassed: boolean;
    constructor(object: CustomRideAttr) {
        super(object);
        this.isPassed = object.isPassed;
    }
}

export function createInitTripInfo(originalTrip: Trip): InitTripInfo {
    const {
        startStationCode,
        destinationStationCode,
        startStationName,
        destinationStationName,
        arrivalTime,
        departureTime,
    } = originalTrip;
    return {
        startStationCode,
        destinationStationCode,
        startStationName,
        destinationStationName,
        arrivalTime,
        departureTime,
    };
}

export function extractPassedRides(
    icInfo: IcInfo,
    selectedStop: string
): IcRide[] {
    const indexOfLastPointRide = icInfo.findIndex(
        (ride) => ride.destinationStationId === selectedStop
    );
    const ridesSet = icInfo
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .slice(0, indexOfLastPointRide + 1);

    return ridesSet.map((ride) => {
        return Object.assign(ride, {
            isPassed: true,
            rideUid: `${ride.lineNumber} | ${ride.tripNumber}`,
        });
    });
}

export function createUid(
    originUid: string | undefined,
    suggestionUid: string | undefined,
    passedRidesCount: number
): string {
    if (!suggestionUid || !originUid) {
        throw new Error(
            '[createUid] suggestionUid, originUid should be a string'
        );
    }
    const isDirect = suggestionUid.search('direct') === 0;
    const isICLegacy = suggestionUid.search(/interconnection:0/) === 0;
    const isICUuid = suggestionUid.search(/ic:/) === 0;

    if (isDirect === false && isICLegacy === false && isICUuid === false) {
        throw new Error(
            `Create custom Uid is failed suggestionUid: '${suggestionUid}'`
        );
    }

    const suggestionTrip = isDirect
        ? parseDirectUuid(suggestionUid)
        : parseIcUuid(suggestionUid);
    const originTrip = parseIcUuid(originUid);
    const results = originTrip
        .splice(0, passedRidesCount)
        .concat(suggestionTrip);

    return stringifyIcUuid(results);
}

export default function genCustomSuggestion(
    suggestion: Suggestion,
    originalTrip: Trip,
    originUids: { originTripId?: string },
    selectedStop: string
): CustomSuggestion {
    const initTripInfo: InitTripInfo = createInitTripInfo(originalTrip);
    const extractedPassedRides = extractPassedRides(
        originalTrip.icInfo || [],
        selectedStop
    );

    const rides = extractedPassedRides.concat(
        suggestion.icInfo as unknown as IcRide[]
    );
    const icInfo = rides;

    return Object.assign(suggestion, {
        startStationCode: originalTrip.startStationCode,
        startStationName: originalTrip.startStationName,
        startStationId: originalTrip.startStationId,
        departureTime: originalTrip.departureTime,
        isCustom: true,
        uid: createUid(
            originUids.originTripId,
            suggestion.uid,
            extractedPassedRides.length
        ),
        initTripInfo,
        icInfo: icInfo,
        breakingPoint: {
            stationId: suggestion.startStationId as string,
            stationName: suggestion.startStationName,
            stationCode: suggestion.startStationCode as string,
            departureTime: suggestion.departureTime,
        },
    });
}
