import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@flixbus/honeycomb-react';
import { Icon, IconArrowRight } from '@flixbus/honeycomb-icons-react';
import useStatus from '../../dataLayer/useStatus';
import { useTranslate } from '../../system/i18n/useTranslate';

export interface StatusInlineProps {
    rideUuid: string;
}

export default function StatusInline({ rideUuid }: StatusInlineProps) {
    const { data } = useStatus(rideUuid);
    const translate = useTranslate();
    if (
        data === undefined ||
        data.statusCode !== 'OK' ||
        data.body.length === 0
    ) {
        return null;
    }
    const statistic = data.body.reduce(
        (acc, st) => {
            let result = { ...acc };
            switch (st.status) {
                case 'ERROR':
                    result.error++;
                    break;
                case 'PENDING':
                    result.pending++;
                    break;
                case 'SUCCESS':
                    result.success++;
                    break;
            }
            return result;
        },
        { error: 0, pending: 0, success: 0 }
    );
    return (
        <div className="rbk-status-inline">
            {translate('active-rebookings')} {data.body.length}: &nbsp;
            {statistic.error > 0 && (
                <span className="rbk-status-inline__item">
                    {translate('error')}: {statistic.error}
                </span>
            )}
            {statistic.pending > 0 && (
                <span className="rbk-status-inline__item">
                    {translate('pending')}: {statistic.pending}
                </span>
            )}
            {statistic.success > 0 && (
                <span className="rbk-status-inline__item">
                    {translate('success')}: {statistic.success}
                </span>
            )}
            <Button
                appearance="tertiary"
                to={`/${rideUuid}/queue`}
                //@ts-ignore
                RouterLink={RouterLink}
            >
                Details <Icon InlineIcon={IconArrowRight} />
            </Button>
        </div>
    );
}
