import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MainWrapper, ThemeWrapper } from '@flixbus/honeycomb-react';
import {
    Provider as RollbarProvider,
    ErrorBoundary as RollbarErrorBoundary,
} from '@rollbar/react';
import App from './App';
import { msalInstance } from './auth';
import reactQueryConfig from './dataLayer/config';
import { AppStateProvider } from './dataLayer/AppState';
import rollbarConfig from './config/rollbar.conf';
import NotificationSystem from './system/Notification/NotificationSystem';

import './config/dayjs.conf';
import './index.scss';
import './DataDog';
import ErrorUiComponent from './components/ErrorComponent/ErrorUiComponent';

const client = new QueryClient(reactQueryConfig);

const container = document.getElementById('app');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <RollbarProvider config={rollbarConfig}>
        <RollbarErrorBoundary fallbackUI={ErrorUiComponent}>
            <Router>
                <ThemeWrapper>
                    <MainWrapper>
                        <NotificationSystem>
                            <QueryClientProvider client={client}>
                                <AppStateProvider>
                                    <App pca={msalInstance} />
                                </AppStateProvider>
                                <ReactQueryDevtools />
                            </QueryClientProvider>
                        </NotificationSystem>
                    </MainWrapper>
                </ThemeWrapper>
            </Router>
        </RollbarErrorBoundary>
    </RollbarProvider>
);
