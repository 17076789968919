import { OrderDetails } from '../../components/types';
import Trip, { AttrsBaseTrip } from './Trip';

export type OrderAttrs = {
    orderId: string | number;
    originTripId: string;
    trip: AttrsBaseTrip[] | null;
    orderDetails: OrderDetails;
};

export default class Order extends Object {
    orderId!: string | number;
    originTripId!: string;
    trip!: Trip;
    orderDetails!: OrderDetails;

    constructor(object: OrderAttrs) {
        super(object);
        Object.assign(this, object);
        if (object.trip === null) {
            return this;
        } else {
            if (object.trip instanceof Trip) {
                this.trip = object.trip;
                return this;
            }
            // add trip id to the trip object. there is missing one
            /**
             * @todo
             * update payload with proper trip id
             *  */

            const fullTrip = object.trip.find(
                //@ts-ignore
                (t) => object.originTripId.search(t.rideId) !== -1
            );
            fullTrip && (fullTrip.uid = object.originTripId);

            if (fullTrip === undefined) {
                throw new Error(
                    `Order ${object.orderId} doesn't contain full trip info`
                );
            }
            if (
                fullTrip.startStationId === undefined &&
                fullTrip.destinationStationId === undefined
            ) {
                const regexp = new RegExp(
                    '(direct:.+:(?<from>.+):(?<to>.+))|(?:ic:(?<fromIc>.+):(?<toIc>.+)/)'
                );
                const match = object.originTripId.match(regexp);
                if (match !== null) {
                    fullTrip.startStationId =
                        match.groups?.from || match.groups?.fromIc || '';
                    fullTrip.destinationStationId =
                        match.groups?.to || match.groups?.toIc || '';
                }
            }
            this.trip = new Trip(fullTrip as unknown as AttrsBaseTrip);
        }
    }
}
