import classNames from 'classnames';
import { Button } from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft, IconClose } from '@flixbus/honeycomb-icons-react';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';

import './SubPage.scss';
import { ReactNode } from 'react';

export interface SubPageProps {
    children: ReactNode[] | ReactNode;
    title: string;
    onClose: () => void;
    extraClasses?: string;
}

export default function SubPage({
    children,
    title,
    onClose,
    extraClasses,
}: SubPageProps) {
    const translate: Translate = useTranslate();
    const content = Array.isArray(children) ? children[0] : children;
    const footer = Array.isArray(children) ? children[1] : null;
    const cssClass = classNames('rbk-sub-page', extraClasses);
    return (
        <div className={cssClass}>
            <div className="rbk-sub-page__wrapper">
                <div className="rbk-sub-page__progress-bar"></div>
                <header className="rbk-sub-page__header">
                    <Button appearance="link" onClick={onClose}>
                        <Icon InlineIcon={IconArrowLeft} />
                        {translate('modal_back')}
                    </Button>
                    <div>{title}</div>
                    <Button appearance="link" onClick={onClose}>
                        <Icon InlineIcon={IconClose} />
                        {translate('modal_cancel')}
                    </Button>
                </header>
                <div className="rbk-sub-page__inner">{content}</div>
            </div>
            {footer}
        </div>
    );
}
