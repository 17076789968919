import {
    useQuery,
    QueryFunctionContext,
    QueryObserverOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import { api } from '../api';
import { RideByOrder } from '../components/types';

function fetch({ queryKey }: QueryFunctionContext): Promise<RideByOrder[]> {
    const [, orderId] = queryKey;
    const url: string = `orderRideInfo/${orderId}`;
    return api
        .get(url)
        .then((response) => response.data.body)
        .catch((e) => {
            if (e.response.data.error && e.response.data.errorMsg) {
                throw (
                    e.response.data.error
                );
            }
            throw new Error(`${e.code}: ${e.message}`);
        });
}

export default function useOrderSearch(
    orderId?: string | number,
    options: QueryObserverOptions<RideByOrder[]> = {}
): UseQueryResult<RideByOrder[]> {
    return useQuery({
        queryKey: ['order', orderId],
        queryFn: fetch,
        ...options,
    });
}
