import { Box, Grid, GridCol, Skeleton } from '@flixbus/honeycomb-react';

function SkeletonItem() {
    return (
        <Box highlighted>
            <Skeleton height="md" width="sm" />
            <Skeleton height="sm" width="lg" />
            <Skeleton height="sm" width="md" />
            <Grid>
                <GridCol>
                    <Skeleton height="lg" />
                </GridCol>
                <GridCol>
                    <Skeleton height="lg" />
                </GridCol>
                <GridCol>
                    <Skeleton height="lg" />
                </GridCol>
                <GridCol>
                    <Skeleton height="lg" />
                </GridCol>
            </Grid>
        </Box>
    );
}

export default function OrderBoxSkeleton() {
    return (
        <>
            <SkeletonItem />
            <SkeletonItem />
        </>
    );
}
