import { useRef, useCallback } from 'react';
import { Box } from '@flixbus/honeycomb-react';
import SuggestionTripCard from '../SuggestionTripCard/SuggestionTripCard';
import useScroll from '../../hoocs/useScroll';

import './Alternatives.scss';
import dayjs from 'dayjs';
import { parseTimezone, SHORT_DATE_FORMAT } from '../../utils';
import { useTranslate } from '../../system/i18n/useTranslate';
import {
    Suggestion,
    SuggestionsCollection,
    OriginTripIds,
} from '../../dataLayer/classes/Trip';

const LIMITED_BY_TIME = 1800000;

export interface AlternativesListProps {
    options: SuggestionsCollection;
    originTrip?: string;
    onSelect?: (suggestion: Suggestion) => void;
    dateSelected?: string;
    originalStartStationName: string;
    originalDestinationStationName: string;
    originalArrivalTime: string;
    originalDepartureTime: string;
    fastSuggestionsIds?: string[];
    originTicketId: string;
    selectedStop?: string;
    size?: 'full';
    limitOptionsTime?: string;
    originFullTripIds?: OriginTripIds;
}

export default function AlternativesList({
    options,
    onSelect = () => {},
    dateSelected,
    originTrip,
    originalStartStationName,
    originalDestinationStationName,
    originalArrivalTime,
    originalDepartureTime,
    fastSuggestionsIds,
    originTicketId,
    selectedStop = '',
    size,
    limitOptionsTime,
    originFullTripIds,
}: AlternativesListProps) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollToRef = useRef<HTMLDivElement | null>(null);
    const refAssigned = useRef(false);
    const translate = useTranslate();

    useScroll({ wrapperRef, scrollToRef });

    const filteredOptions = options.filter((option) => {
        if (option.gettingStatus === 'ERROR') {
            return false;
        }
        // hide options with missing departureTime and arrivalTime
        if (option.departureTime === null || option.arrivalTime === null) {
            return false;
        }
        // hide options for another day, injected original trip also match
        const formatDeparture = dayjs
            .utc(option.departureTime)
            .utcOffset(parseTimezone(option.departureTime))
            .format(SHORT_DATE_FORMAT);

        if (
            dateSelected &&
            !dayjs(dateSelected).isSame(formatDeparture, 'day')
        ) {
            return false;
        }

        return true;
    });

    // We need to set ref to the scroll element only once
    const setRef = useCallback((el: HTMLDivElement | null) => {
        if (!refAssigned.current && el) {
            scrollToRef.current = el;
            refAssigned.current = true;
        }
    }, []);

    if (filteredOptions.length === 0) {
        return <Box>{translate('no-alternatives')}</Box>;
    }

    let isLater = false;
    let cssClass = ['alternatives-scroll-wrapper'];
    if (size) {
        cssClass.push('alternatives-scroll-wrapper--full');
    }

    return (
        <div ref={wrapperRef} className={cssClass.join(' ')}>
            {filteredOptions.map((option) => {
                if (!isLater) {
                    isLater = option.uid === originTrip;
                }
                const isFastSuggestion = Boolean(
                    option.uid && fastSuggestionsIds?.includes(option.uid)
                );
                const isOriginalTrip = (): boolean => {
                    if (
                        option.uid === originTrip &&
                        originFullTripIds &&
                        originFullTripIds.originStartStationId ===
                            option.startStationId &&
                        originFullTripIds.originDestinationStationId ===
                            option.destinationStationId
                    ) {
                        return true;
                    }

                    return false;
                };
                const isOriginalRide =
                    originFullTripIds &&
                    option.ifOriginalRide(originFullTripIds) &&
                    !isOriginalTrip();
                const isLimitedByTime = limitOptionsTime
                    ? new Date(option.departureTime).getTime() -
                          new Date(limitOptionsTime).getTime() <
                      LIMITED_BY_TIME
                    : false;
                const shouldAssignRef =
                    !refAssigned.current &&
                    (isOriginalTrip() || isOriginalRide);

                return (
                    <SuggestionTripCard
                        key={option.uid}
                        suggestion={option}
                        onSelect={onSelect}
                        original={isOriginalTrip()}
                        originalStartStationName={originalStartStationName}
                        originalDestinationStationName={
                            originalDestinationStationName
                        }
                        originalArrival={originalArrivalTime}
                        originalDeparture={originalDepartureTime}
                        ref={shouldAssignRef ? setRef : null}
                        isOriginalRide={isOriginalRide}
                        isLater={isLater}
                        isFastSuggestion={isFastSuggestion}
                        originTicketId={originTicketId}
                        selectDisabled={
                            Boolean(option.uid) &&
                            !option.uid.includes(selectedStop)
                        }
                        isLimitedByTime={isLimitedByTime}
                    />
                );
            })}
        </div>
    );
}
