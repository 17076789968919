import { Heading } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconArrowBigRight,
    IconCalendar,
} from '@flixbus/honeycomb-icons-react';
import dayjs from 'dayjs';
import { parseTimezone } from '../../utils/index';
import { Trip } from '../types';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';

export type OrderTripInfoProps = Pick<
    Trip,
    | 'startStationName'
    | 'destinationStationName'
    | 'arrivalTime'
    | 'departureTime'
    | 'arrivalNextDays'
>;

export default function OrderTripInfo({
    startStationName,
    destinationStationName,
    arrivalTime,
    departureTime,
    arrivalNextDays,
}: OrderTripInfoProps) {
    const translate: Translate = useTranslate();
    const dateTimeFormatted = `${
        departureTime === null
            ? '--'
            : dayjs
                  .utc(departureTime)
                  .utcOffset(parseTimezone(departureTime))
                  .format('ddd, DD MMM HH:mm')
    } - ${
        arrivalTime === null
            ? '--'
            : dayjs
                  .utc(arrivalTime)
                  .utcOffset(parseTimezone(arrivalTime))
                  .format('HH:mm')
    }`;
    return (
        <>
            <Heading size={4} flushSpace extraClasses="rbk-order-box__heading">
                {translate('original_ride')}:{' '}
                <span>
                    <span className="rbk-icon-alignment">
                        {startStationName}
                    </span>
                    &nbsp;
                    <Icon InlineIcon={IconArrowBigRight} size={2} />
                    &nbsp;
                    <span className="rbk-icon-alignment">
                        {destinationStationName}
                    </span>
                    &nbsp;&nbsp;
                    <Icon InlineIcon={IconCalendar} />
                    <span className="rbk-icon-alignment">
                        {dateTimeFormatted}
                        {arrivalNextDays && <sup>{arrivalNextDays}</sup>}
                    </span>
                </span>
            </Heading>
        </>
    );
}
