import { datadogRum } from '@datadog/browser-rum';
import { v4 as uuidv4 } from 'uuid';
import { getUser } from '../auth/';

type STEPS =
    | 'search'
    | 'select'
    | 'select-IC-station'
    | 'rebook'
    | 'complete'
    | 'reset'
    | 'error';

export function ddErrorLoginFail(context: { type: string; message: string }) {
    datadogRum.addError('login-fail', context);
}

export class BatchAltTripTracking {
    uid: string;
    group: string;
    orders: (string | number)[];
    altTrip?: string;
    user: any;

    constructor(group: string, orders: (string | number)[]) {
        this.uid = `${uuidv4()}-${new Date().getTime()}`;
        this.group = group;
        this.orders = orders;
        this.user = getUser();
    }
    track(step: STEPS, context?: { [key: string]: any }) {
        if (context?.altTrip) {
            this.altTrip = context.altTrip;
        }

        const trackObj = {
            step,
            user: this.user
                ? { name: this.user.profile.name, email: this.user.userName }
                : 'no user',
            uid: this.uid,
            group: this.group,
            orders: this.orders.join(','),
            ordersCount: this.orders.length,
            altTrip: this.altTrip,
            ...context,
        };

        datadogRum.addAction('alt-trip-tracking', trackObj);
    }
}

export class SingleAltTripTracking {
    uid: string;
    ride: string;
    order: number;
    altTrip?: string;
    icStation?: string;
    user: any;

    constructor(ride: string, order: number) {
        this.uid = `${uuidv4()}-${new Date().getTime()}`;
        this.ride = ride;
        this.order = order;
        this.user = getUser();
    }
    track(step: STEPS, context?: { [key: string]: any }) {
        if (context?.altTrip) {
            this.altTrip = context.altTrip;
        }
        if (context?.icStation) {
            this.icStation = context.icStation;
        }

        const trackObj = {
            step,
            user: this.user
                ? { name: this.user.profile.name, email: this.user.userName }
                : 'no user',
            uid: this.uid,
            ride: this.ride,
            order: this.order,
            altTrip: this.altTrip,
            icStation: this.icStation,
            ...context,
        };

        datadogRum.addAction('single-alt-trip-tracking', trackObj);
    }
}
