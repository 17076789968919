import { Illustration } from '../components/Layout';

import './IndexPage.scss';
import OrderSearchForm from '../components/OrderSeachForm/OrderSearchForm';

export default function IndexPage() {
    return (
        <>
            <Illustration />
            <div className="rbk-index-page">
                <OrderSearchForm />
            </div>
        </>
    );
}
