import { useState, useEffect } from 'react';
import { useAppState, ACTIONS } from '../dataLayer/AppState';
import { GhostMeta } from '../dataLayer/AppState';

type orders = null | number[] | undefined;
interface WindowMessage {
    data: {
        channel: string;
        payload: {
            ordersIds: number[];
            isGhost?: boolean;
            ghostMeta: GhostMeta;
        };
    };
}

export default function useOpenerData(): orders {
    const [orders, setOrders] = useState<orders>(undefined);
    const [appState, dispatch] = useAppState();
    const { searchOrder = null } = appState;

    useEffect(() => {
        let timer: null | ReturnType<typeof setTimeout> = null;
        function messageHandler(e: WindowMessage) {
            const { data } = e;
            const { channel, payload } = data;

            if (channel === 'Rideviewer') {
                if (timer !== null) {
                    clearTimeout(timer);
                }
                if (payload.isGhost) {
                    dispatch(ACTIONS.IS_GHOST_SET, true);
                }
                if (payload.ghostMeta) {
                    dispatch(ACTIONS.GHOST_META_SET, payload.ghostMeta);
                }
                setOrders(payload.ordersIds);
                window.opener.postMessage(
                    { action: 'complete', channel: 'RebookR' },
                    document.referrer
                );

                window.removeEventListener('message', messageHandler);
            }
        }
        if (window.opener && document.referrer) {
            window.addEventListener('message', messageHandler);

            window.opener.postMessage(
                {
                    action: 'ready',
                    channel: 'RebookR',
                    origin: window.location.origin,
                },
                document.referrer
            );
            timer = setTimeout(() => {
                setOrders([]);
            }, 100);
        } else {
            setOrders([]);
        }
        return () => {
            window.removeEventListener('message', messageHandler);
            if (timer !== null) {
                clearTimeout(timer);
            }
        };
    }, [dispatch]);

    return searchOrder || orders;
}
