import {
    useQuery,
    QueryFunctionContext,
    QueryObserverOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import { api } from '../api';
import { PaxTicketsStatusesByRideUuid } from '../components/types';

async function fetch({
    queryKey,
}: QueryFunctionContext): Promise<PaxTicketsStatusesByRideUuid> {
    const [, statusesRequestsIDs] = queryKey;
    const url: string = `pax/checkins/batch`;
    try {
        const response = await api.post(url, statusesRequestsIDs);
        return response.data.body;
    } catch (e: any) {
        if (e.response.data?.error && e.response?.data.errorMsg) {
            throw new Error(
                `${e.response.data.error}: ${e.response.data.errorMsg}`
            );
        }
        throw new Error(`${e.code || e.name}: ${e.message}`);
    }
}

export default function usePaxStatuses(
    statusesRequestsIDs?: { [key: string]: string[] },
    options: QueryObserverOptions<PaxTicketsStatusesByRideUuid> = {}
): UseQueryResult<PaxTicketsStatusesByRideUuid> {
    return useQuery({
        queryKey: ['paxStatuses', statusesRequestsIDs],
        queryFn: fetch,
        ...options,
    });
}
