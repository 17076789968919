import { AutocompleteOptionType } from '@flixbus/honeycomb-react';
import { ReplaceTrip } from '../dataLayer/AppState/types';
import { UseQueryResult } from '@tanstack/react-query';

export enum PAX_STATUS {
    Checked = 'CHECKED',
    Absent = 'ABSENT',
    Unchecked = 'UNCHECKED',
}

export type DirectInterconnection = 'DIRECT' | 'INTERCONNECTION';

export interface RideBaseAttributes {
    rideUid: string;
    rideUuid?: string;
    startStationCode?: string;
    destinationStationCode?: string;
    startStationName: string;
    startStationId?: string;
    destinationStationName: string;
    destinationStationId?: string;
    arrivalTime: string;
    departureTime: string;
}

export interface RideOptionalAttributes {
    lineNumber?: string;
    tripNumber?: string;
    arrivalTimeText?: string;
    departureTimeText?: string;
    isSameDayOfArrival?: boolean;
    arrivalTimeTitle?: string;
    departureTimeTitle?: string;
}

export interface Ride extends RideBaseAttributes {
    rideUuid: string;
    lineColor?: string | null;
    rideUid: string;
    transportType?: string;
    isPassed?: boolean;
}

export interface RideEntityAttributes extends Ride, RideOptionalAttributes {}

export interface Suggestion extends RideBaseAttributes {
    uid: string;
    suggestionTripType: DirectInterconnection;
    capacity?: Capacity;
    rides: Ride[];
    gettingStatus: string;
    gettingMessage: string | null;
    disabled?: boolean;
    isCustom?: boolean;
    breakingPoint?: BreakingPoint;
    limitByConnection?: boolean;
}

export interface Capacity {
    seats: number;
    slots: number;
}

export interface Passenger {
    name: string;
    lastName: string;
    gender?: 'male' | 'female' | '';
    ticketId?: string;
}

export interface Product {
    name: string;
    id?: string;
}

export interface OrderDetails {
    passengers: Passenger[];
    products: Product[];
}
export interface IcInfo
    extends Omit<
        RideBaseAttributes,
        'startStationCode' | 'destinationStationCode'
    > {
    sortOrder: number;
    rideUuid: string;
    lineNumber?: string;
    tripNumber?: number | string;
    lineColor?: string | null;
    rideUid: string;
}

export interface CustomSuggestion extends Suggestion {
    initTripInfo: InitTripInfo;
    rides: CustomRide[];
    isCustom?: boolean;
    breakingPoint: BreakingPoint;
}

export interface CustomRide extends Ride {
    sortOrder?: number;
    lineNumber?: string;
    tripNumber?: number | string;
}

export interface BreakingPoint {
    stationId: string;
    stationName: string;
    stationCode: string;
    departureTime: string;
}

export type InitTripInfo = Pick<
    Trip,
    | 'startStationCode'
    | 'destinationStationCode'
    | 'startStationName'
    | 'destinationStationName'
    | 'arrivalTime'
    | 'departureTime'
>;

export type FormatMinutes = [number, number, number];

export interface Hop {
    from: IcInfo;
    to: IcInfo;
    at: string;
    transfer: FormatMinutes;
    preselected?: boolean;
}

export type SuggestionType = 'FULL' | 'PARTIAL';

export interface Trip {
    id?: string;
    rideUuid: string;
    rideUid: string;
    rideId: string;
    lineColor?: string;
    startStationId: string;
    startStationCode: string;
    destinationStationId?: string;
    destinationStationCode: string;
    startStationName: string;
    destinationStationName: string;
    arrivalTime: string;
    departureTime: string;
    suggestionType: SuggestionType;
    suggestions: Suggestion[];
    capacity?: Capacity;
    icInfo?: IcInfo[];
    arrivalNextDays?: string;
}

export interface Order {
    orderId: string | number;
    originTripId?: string;
    trip: Trip[];
    orderDetails: OrderDetails;
}
export interface OrderAlternatives extends Order {
    fastSuggestionsIds: string[];
}

export interface SelectedOption {
    orderId: string | number;
    option: ReplaceTrip;
    suggestion?: Suggestion;
}

export interface RebookingOptions {
    orderId: number;
    rebookingOptions: Suggestion[];
}

export interface ResponseWithRebookingOptions {
    content: RebookingOptions[];
}

export interface ResponseWithContent {
    content: Order[];
}

export interface ResponseWithOrder {
    total: 0;
    offset: 0;
    batch: 0;
    orders: Order[];
    totalPages: number;
}

export interface Pagination {
    pageable: {
        sort: { sorted: boolean; unsorted: boolean; empty: boolean };
        pageNumber: number;
        pageSize: number;
        offset: number;
        paged: boolean;
        unpaged: boolean;
    };
    last: boolean;
    totalPages: number;
    totalElements: number;
    sort: { sorted: boolean; unsorted: boolean; empty: boolean };
    number: number;
    first: boolean;
    numberOfElements: number;
    size: number;
    empty: boolean;
}

export interface ResponseWithSuggestions {
    content: Suggestion[];
}

export interface Response extends ResponseWithContent, ResponseWithOrder {}

export interface RebookStatus {
    id: string;
    rideUuid: string;
    orderId: string;
    newOrderId?: string;
    originTripId: string;
    replaceTripId: string;
    notify: boolean;
    createdAt: number;
    createdBy: string;
    status: 'PENDING' | 'SUCCESS' | 'ERROR';
    response: null | string;
}

export type StatusCode = 'OK' | 'ERROR';

export interface StatusResponse {
    statusCode: StatusCode;
    body: RebookStatus[];
}

export interface TicketResponse {
    statusCode: StatusCode;
    body: string;
}
export interface DurationDifference {
    duration: string;
    isFaster: boolean;
}

export interface RideByOrder {
    startStationCode?: string;
    destinationStationCode?: string;
    startStationName: string;
    destinationStationName: string;
    arrival: string;
    departure: string;
    arrivalTime: string;
    departureTime: string;
    type: DirectInterconnection;
    rideId: string;
    lineDetails: Line;
    icInfo?: IcInfo[];
    tripNumber?: number;
}

export interface Line {
    id: string;
    code: string;
    cooperation: boolean;
    color: string;
    concessionOwnerDetails: ConcessionOwner;
}

export interface ConcessionOwner {
    id: string;
    name: string;
}

export type PaxStatusesByRideUuidResponse =
    UseQueryResult<PaxTicketsStatusesByRideUuid>;

export interface PaxResponseByRideUuid {
    passengerUuid: string;
    rideUuid: string;
    stationUuid: string;
    status: PAX_STATUS;
    ticketId: string;
    timestamp: number;
}

export type PaxTicketsByRideUuid = { [key: string]: string[] };

export type PaxTicketsStatusesByRideUuid = {
    [key: string]: PaxResponseByRideUuid[];
};

export interface Stop {
    id: string;
    cityId: string;
    name: string;
    shortName: string;
    code: string;
    timezone: string;
    latitude: number;
    longitude: number;
    countryCode: string;
    subdivision?: null;
    locale?: null;
    closeSalesOffset: number;
    zipCode: string;
    addressCity: string;
    addressLines: string[];
}

export interface AutocompleteStopsList extends AutocompleteOptionType {
    id: Stop['id'];
    name: Stop['name'];
    code: Stop['code'];
}
