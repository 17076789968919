import { ReactNode } from 'react';

import './SimpleConnection.scss';

type SimpleConnectionProps = {
    title?: ReactNode;
    titleAlign?: 'left' | 'right' | 'center';
    children: ReactNode;
};
export default function SimpleConnection({
    title,
    titleAlign = 'left',
    children,
}: SimpleConnectionProps) {
    return (
        <table className="rbk-simple-connection">
            {title !== undefined && (
                <caption style={{ textAlign: titleAlign }}>{title}</caption>
            )}
            <tbody>{children}</tbody>
        </table>
    );
}
